.history-screen {
    background-color: #f8f9fa; /* Atualizado para match com PCI */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px; /* Aumentado de 20px para 40px */
    width: 100vw;
    min-height: 100vh; /* Garantir altura mínima */
}

.header_history {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
}

.image-container_history_01 {
    position: absolute;
    top: 0px;
    left: 20px;
    padding: 0;
    background-color: transparent;
    z-index: 1001;
}

.image-container_history_02 {
    position: absolute;
    top: 0px;
    left: 63px;
    padding: 0;
    background-color: transparent;
    z-index: 1001; /* Garante que fique acima dos outros elementos */
}

.logo {
    width: 140px;
    height: auto;
    cursor: pointer;
}

.divAreaImageHistory {
    max-width: 1300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}

/* .images-grid { 
    display: grid;
    overflow-y: auto;
    border-radius: 10px;
    grid-template-columns: repeat(4, 0fr);
    grid-gap: 0px 30px;
    /* Espaçamento entre as imagens *
    padding-left: 50px;
    padding-right: 50px;
    margin: 0 auto;
    max-width: 100%;
    max-height: 70%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}  */

.images-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1300px;
    background: #f8f9fa;
    border-radius: 16px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.grid-content {
    display: grid;
    grid-template-columns: repeat(4, minmax(280px, 1fr));
    row-gap: 20px;
    column-gap: 20px;
    padding: 10px 20px 20px;
    height: calc(100vh - 220px);
    overflow-y: auto;
}

.date-label {
    position:relative;
    margin-bottom: 5px;
    top: 0px;
    background-color: #ffffff;
    right: 100%;
    left: 0%;
    color: #757575;
    font-size: 10px;
    width: 200px;
    text-align: center;
}

.categoryTitleHistory {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 16px 24px;
    color: #2d3748;
    font-weight: 600;
    font-size: 24px;
    border-bottom: 1px solid #e2e8f0;
}

.categoryTitleHistory span {
    flex-shrink: 0;
}

.buttonGroupImgHist {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0;
}

.positionRadio {
    margin: 0;
    color: #757575;
    font-weight: normal;
    font-size: 12.2px; /* Reduzido em 15% do tamanho anterior de 14.4px */
}

.slcSlidesLayHis {
    min-width: 140px !important;
}

/* Contêiner Principal para Cada Post */
.post-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding: 8px;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.post-container:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Contêiner de Status */
.status-container {
    display: flex;
    align-items: center;
    gap: 6px;
    width: 100%;
    padding: 2px 0;
}

/* Indicador de Status (Bolinha) */
.status-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    flex-shrink: 0;
}

/* Texto de Status */
.status-text {
    font-size: 12px;
    color: #757575;
    font-weight: 500;
}

/* Contêiner para Imagens */
.image-wrapper {
    position: relative;
    width: 280px;
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: 1;
    margin: 0 auto;
}

/* Contêiner para os Botões */
.buttons-container {
    display: flex;
    gap: 24px;
    padding: 2px 0;
}

/* Botões de Ação */
.action-button {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    background-color: #3f3d454a;
    color: white;
    border: 2px solid #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.action-button:hover {
    background-color: #3f3d454a;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.buttonIcon {
    font-size: 21px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    font-weight: bold;
    transition: opacity 0.3s;
    opacity: 0;
    pointer-events: none;
}

.image-wrapper:hover .overlay {
    opacity: 0.5;
}

.image-wrapper:hover .click-indicator {
    opacity: 1;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

/* Adicionando a classe de atualização */
.overlay.updating {
    animation: blink 1.5s infinite;
    opacity: 1;
    /* Deixa visível a overlay enquanto pisca */
}

/* HistoryScreen.css */
.post-container .edit-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 10;
}

.post-container.editing .edit-mask {
    opacity: 1;
}

.history-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    transition: transform 0.3s ease;
}

.spinner {
    position: absolute;
    /* Necessário para centralizar */
    top: 50%;
    /* Centraliza verticalmente */
    left: 50%;
    /* Centraliza horizontalmente */
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #8E59FF;
    width: 40px;
    /* Largura do spinner */
    height: 40px;
    /* Altura do spinner */
    transform: translate(-50%, -50%);
    /* Ajusta para centralizar no centro exato */
    animation: spin 2s linear infinite;
    /* Animação de rotação */
}

.no-data-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 40px;
    color: #757575;
    transform: translate(-50%, -50%);
}

.stories-size {
    height: auto;
    width: 240px; /* Reduzido de 253px para 240px (5% menor) */
    aspect-ratio: 0.83;
}

@media (max-width: 1280px) {

    .images-grid {

        width: 1220px;
        height: 80%;
        grid-gap: 0px 8px;
        margin-bottom: -40px;
    }

    .image-wrapper {
        width: 242px;
    }

    .stories-size {
        width: 209px; /* Reduzido de 220px para 209px (5% menor) */
        aspect-ratio: 0.83;
    }

    .status-container {
        width: 86%;
    }

    .date-label {
        font-size: 10px;
    }

    .overlay {
        font-size: 20px;
        margin-bottom: 0px;
    }
}

@media (max-width: 768px) {

    .history-screen {
        
        min-height: 80vh; /* Garantir altura mínima */
    }

    .logo {
        width: 100px;
        /* Largura do logo */
    }

    .divAreaImageHistory {
        margin-top: 56.5px; /* Mantendo consistência no mobile */
        padding: 0 16px;
    }

    .images-grid {
        
        width: 370px;
        height: 40%;
        grid-gap: 0px 10px;
        margin-bottom: 40px;
    }

    .grid-content {
        grid-template-columns: 1fr;
        padding: 16px;
        row-gap: 10px;
        height: calc(100vh - 340px);
    }

    .spinner {
        position: absolute;
        margin-top: 100%;
        margin-left: -20px;
    }

    .post-container {
        padding: 4px;
    }

    .categoryTitleHistory {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        padding: 14px 20px;
    }

    .buttonGroupImgHist {
        width: 100%;
        justify-content: flex-start;
        padding: 0;
    }

    .action-button {
        height: 44px;
        width: 44px;
    }

    .buttonIcon {
        font-size: 18px;
    }

    .positionRadio {
        margin-top: -7.5px;
        font-size: 11px; /* Reduzido em 15% do tamanho anterior de 13px */
    }

    .slcSlidesLayHis {
        min-width: 100px !important;
    }

    .status-container {
        width: 78%;
    }

    .stories-size {
        width: 209px; /* Reduzido de 220px para 209px (5% menor) */
        aspect-ratio: 0.83;
    }

    .image-wrapper {
        width: 242px;
    }

    .buttons-container {
       margin-bottom: 10px;
    }
}

.menu-icon {
    font-size: 32px !important; /* Forçar o tamanho do ícone */
    color: #757575;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.menu-icon:hover {
    transform: scale(1.1);
}

.click-indicator {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    z-index: 2;
    font-size: 14px;
    text-align: center;
    pointer-events: none;
    white-space: pre-wrap;
    line-height: 1.4;
}