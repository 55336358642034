.content-analysis {
    background-color: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 20px;
    width: 100vw;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    background-color: #fff;

}

.menu {
    margin-top: 7px;
    width: 45px;
    height: auto;
    cursor: pointer;
}

.logo {
    margin: 7px;
    width: 140px;
    height: auto;
    cursor: pointer;
}

.image-container_01 {
    position: absolute;
    top: 0px;
    left: 20px;
    padding: 0;
    background-color: transparent;
}

.image-container_02 {
    position: absolute;
    top: 0px;
    left: 63px;
    padding: 0;
    background-color: transparent;
}

.user-info {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 6px;
    background-color: #CCCCCC;
    color: #fff;
    font-size: 17px;
    cursor: pointer;

}

.user-profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    border: 2px solid white;
}

.user-credits {
    margin-left: 10px;
    font-size: 17px;
}


.container_02 {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-bottom: 20px;
    position: center;
}

.screenTitle {
    margin-top: 40px;
    font-size: 24px;
    width: 100%;
    font-weight: bold;
    margin-bottom: 20px;
}

.insideItens {
    width: 60%;
}

.form-row_02 {
    display: flex;
    align-items: center; /* Alinha verticalmente os itens no centro */
    justify-content: space-between; /* Espaçamento entre os itens */
}

.content-analysis input[type="text"] {
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin-right: 10px;
    margin-top: 15px;
    font-size: 14px;
    width: 80%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    height: 40px;
}

.submit-button_02 {
    display:flex;
    align-items: center; 
    justify-content: center;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    transition: background-color 0.3s ease;
    width: 18%;
    height: 40px;
    margin-top: 15px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}


.submit-button_02:hover {
    /* Efeito de hover: escurecendo levemente o botão */
    filter: brightness(95%);
}

.submit-button_02:active {
    /* Efeito de clique: escurecendo ainda mais o botão */
    filter: brightness(75%);
}


.buttonIcon_02 {
    font-size: 25px;
    
}


.response-section_02 {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

input::placeholder {
    color: #999;
    /* Cor do placeholder */
    font-style: italic;
    /* Estilo da fonte */
    opacity: 0.6;
    /* Certifique-se de que o placeholder é sempre visível */
}


.response-section-padding_02 {
    padding: 20px;
}

.response-row_02 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
}

.response-row_02 .txtArea {
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    width:  560px;
    margin-right: 0px;
    margin-left: 10px;
    height: 310px;
    overflow-y: auto; /* Permite rolagem se o conteúdo exceder a altura */
}


.response-image_02 {
    /*  width: 300px;  Isso fará com que a imagem ocupe 30% da linha */
    /*  height: 300px;  Isso mantém a proporção da imagem */
    border: 1px solid #dddddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    /* Faz a imagem preencher a largura do contêiner */
    height: auto;
    /* Mantém a proporção da imagem */
    object-fit: contain;
    /* Garante que a imagem seja redimensionada para caber dentro do contêiner sem distorcer */

}

.response-image-default {

    border: 1px solid #dddddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    width: 30%;
    /* Faz a imagem preencher a largura do contêiner */
    height: auto;
    /* Mantém a proporção da imagem */
    object-fit: contain;
    /* Garante que a imagem seja redimensionada para caber dentro do contêiner sem distorcer */
}

.linkbox {
    width: 30%;
    height: auto;
}

.labelFont {
    font-weight: bold;
    color: gray;

}

.labelFont-02 {
    font-weight: bold;
    color: gray;
    margin-bottom: 100px;
}

.txtArea {
    font-family: Arial;
    font-size: 14px;
}


@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.blinking-text {
    animation: blink 1.2s linear infinite;
}

.loading-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 120px;
    /* Ajuste o tamanho conforme necessário */
    height: 100%;
    /* Garantir que a área de texto ocupe toda a altura disponível */
    text-align: center;
    /* Ajuste conforme necessário */
}

.image-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 310px;
    /* Ajuste conforme necessário */
    width: 30%;
    /* Ajuste conforme necessário */
    background-color: #f0f0f0;
    /* Cor de fundo do loader */
    border: 1px solid #dddddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}


.spinner_01 {
    border: 4px solid rgba(0, 0, 0, 0.1);
    /* Cor de fundo do spinner */
    border-radius: 50%;
    border-top: 4px solid #8E59FF;
    /* Cor do spinner */
    width: 40px;
    /* Tamanho do spinner */
    height: 40px;
    /* Tamanho do spinner */
    animation: spin 2s linear infinite;
}

.menu-icon{
    font-size: 45px;
    margin-top: 5px;
    cursor: pointer;
    color: #757575;
}

.text-mobile {
    display: none;
}

.text-desktop {
    display: inline;
}



@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 768px) {

    .user-credits {
        display: none;
    }

    .userInfoMobile {
        display: block;
        margin-left: 10px;
    }

    .insideItens {
        margin-top: 75px;
        width: 90%;
    }

    .screenTitle {
        font-size: 1.2em;
        /* Reduzir um pouco o tamanho da fonte para otimizar o espaço */
        margin: 0;
        margin-bottom: 20px;
        /* Remover margens padrão */
    }

    .labelFont {
        font-size: 1em;
    }

    .content-analysis input[type="text"] {
        width: calc(76% - 20px);
        margin-right: 12px;
    }

    .submit-button_02 {
        width: 26%;
    }

    .response-row_02 {
        display: flex;
        flex-direction: column;
        margin-top: 0px;
        /* Direciona os elementos filhos em colunas */
        align-items: center;
        /* Alinha os elementos filhos ao centro horizontalmente */
        width: 100%;
    }

    

    .response-image_02 {
        margin-bottom: 10px;
        /* Adiciona espaço abaixo da imagem */
    }

    .linkbox {
        width: 98%;
        height: auto;
    }

    .image-loader {
        width: 98%;
    }

    .response-image-default {
        width: 98%;
    }

    .response-row_02 .txtArea {
        padding: 10px;
        border: 1px solid #dddddd;
        border-radius: 5px;
        font-size: 14px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        width:  303px;
        margin-top: 20px;
        margin-right: 10px;
        margin-left: 10px;
        height: 310px;
        overflow-y: auto; /* Permite rolagem se o conteúdo exceder a altura */
    }

    .menu {
        margin-top: 7px;
        width: 30px;
        height: auto;
    }

    .image-container_02 {

        left: 48px;
    }

    .logo {
        margin: 7px;
        width: 100px;
        height: auto;
    }

    .my-swal-alert {
        font-size: 14px;
        width: 85%;
    }

    .menu-icon {
        font-size: 35px;
    }

    .labelFont-02 {
        font-size: 1em;
        margin-bottom: 25px;
    }

    .buttonIcon_02 {
        display: none; /* Oculta o ícone por padrão */
    }

    .text-desktop {
        display: none;
    }

    .text-mobile {
        display: inline;
    }

}