.image-container-drawer {
    position: absolute;
    top: 10px;
    left: 26.5px;
    padding: 0;
    background-color: transparent;
    transition: transform 0.3s ease;
}

.image-container-drawer:hover {
    transform: scale(1.02);
}

.slide-image-drawer {
    width: 200px;
    height: auto;
    transition: opacity 0.3s ease;
}

.list-item-icon {
    font-size: 22px;
    margin-right: 10px;
    transition: color 0.3s ease;
}

/* Estilo para os itens do menu */
.drawer-list-item {
    padding: 8px 14px;
    margin: 2px 0;
    border-radius: 8px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.drawer-list-item:hover {
    background-color: rgba(128, 90, 213, 0.1);
    transform: translateX(5px);
}

.drawer-list-item:hover .list-item-icon {
    color: #805AD5 !important;
}

/* Estilo para o divisor */
.drawer-divider {
    margin: 10px 0;
    opacity: 0.1;
}

/* Estilo para os links do footer */
.drawer-footer-links {
    display: flex;
    gap: 16px;
    font-size: 11px;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.drawer-footer-links a {
    color: #757575;
    text-decoration: none;
    transition: color 0.3s ease;
}

.drawer-footer-links a:hover {
    color: #805AD5;
    text-decoration: none;
}

.new-textarea{
    border: 1px solid #767676;
    height: 130px;
    padding: 10px;
}

@media (max-width: 768px) {
    .list-item-icon {
        font-size: 20px;
        margin-right: 8px;
    }

    .drawer-list-item {
        padding: 6px 10px;
        font-size: 13px;
    }

    .slide-image-drawer {
        width: 200px;

    }

    .image-container-drawer {
        position: absolute;
        top: 10px;
        left: 25px;
        padding: 0;
        background-color: transparent;
        transition: transform 0.3s ease;
    }

    .myCustomTitle {
        font-size: 18px;
    }

    .my-swal {
        font-size: 12px;
        width: 85%;
    }

    .my-swal-alert {
        font-size: 14px;
        width: 85%;
    }
}