.automation {
    overflow: hidden;
    background-color: #f8f9fa;
    height: 100vh;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
}

.image-container_automacao {
    position: absolute;
    top: 0px;
    left: 20px;
    padding: 0;
    background-color: transparent;
}

.image-container_automacao_02 {
    position: absolute;
    top: 0px;
    left: 63px;
    padding: 0;
    background-color: transparent;
}

.automation-container {
    max-width: 1350px;
    margin: 0 auto;
    padding: 0 20px;
    margin-top: 75px;
    height: calc(100vh - 75px);
    overflow: hidden;
}

.posts-container {
    height: calc(100vh - 198.5px);
    overflow: hidden;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.post-selection-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}

.post-card {
    position: relative;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    overflow: hidden;
}

.post-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.post-card:hover .click-indicator {
    opacity: 1;
}

.click-indicator {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    z-index: 2;
    font-size: 14px;
    text-align: center;
}

.post-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.post-info {
    padding: 8px 12px;
    background: white;
    border-top: 1px solid #f0f0f0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.post-date {
    font-size: 12px;
    color: #666;
    margin-bottom: 0px;
}

.post-stats {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    gap: 12px;
    margin-left: -15px;
}

.automation-form {
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 24px;
    margin-top: 20px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 16px;
}

.form-group textarea {
    min-height: 120px;
    resize: vertical;
}

.keyword-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
}

.keyword-tag {
    background: #8E59FF;
    color: white;
    padding: 6px 12px;
    border-radius: 16px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.remove-tag {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.save-button {
    background: #8E59FF;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.save-button:hover {
    background: #7040FF;
}

.tabs-container {
    margin-bottom: 24px;
    position: relative;
}

.tab-list {
    display: flex;
    gap: 8px;
    margin-bottom: 24px;
    border-radius: 8px;
    padding: 4px;
}

.tab {
    padding: 8px 16px;
    cursor: pointer;
    color: #757575;
    font-weight: 500;
    border-radius: 8px;
    transition: all 0.2s ease;
    position: relative;
    background: #fff;
    border: none;
    min-width: 100px;
    text-align: center;
    font-size: 14px;
}

.tab.active {
    color: white;
    background: #8E59FF;
    border: none;
}

.tab:hover:not(.active) {
    background: #f0f0f0;
    color: #757575;
    border: none;
}

.status-badge {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 500;
    margin-right: 4px;
    margin-left: 12px;
}

.status-badge svg {
    margin-top: -1px;
}

.status-badge.active {
    background: #E6F4EA;
    color: #1E8E3E;
}

.status-badge.inactive {
    background: #FEE8E7;
    color: #D93025;
}

.automation-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 24px;
}

.stat-card {
    border: 2px solid #e2e8f0;
    background: white;
    border-radius: 12px;
    padding: 20px;
}

.stat-value {
    font-size: 24px;
    font-weight: 600;
    color: #8E59FF;
    margin-bottom: 8px;
}

.stat-label {
    color: #666;
    font-size: 14px;
}

.automations-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    height: calc(100vh - 320px);
    overflow-y: auto;
}

.automation-card {
    background: white;
    border-radius: 12px;
    border: 2px solid #e2e8f0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: fit-content;
}

.automation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    padding-top: 15px;
    
    border-bottom: 1px solid #e2e8f0;
}

.automation-header-left {
    display: flex;
    align-items: center;
    gap: 12px;
}

.post-id {
    background: #f0f0f0;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    color: #666;
    font-family: monospace;
}

.automation-name {
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

.automation-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
}

.keywords-section {
    flex: 1;
}

.keywords-section strong {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
    display: block;
}

.keyword-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.keyword-tag {
    background: #f0f0f0;
    color: #666;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 13px;
}

.stats-section {
    display: flex;
    gap: 24px;
    padding: 12px;
    background: #f8fafc;
    border-radius: 8px;
}

.stat-item {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #757575;
    font-size: 13px;
    min-width: fit-content;
}

.stat-number {
    font-size: 13px;
    font-weight: 500;
    margin-left: 2px;
}

/* Customização da barra de rolagem apenas para a grid */
.automations-list::-webkit-scrollbar {
    width: 6px;
    display: block;
}

.automations-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
}

.automations-list::-webkit-scrollbar-thumb {
    background: #8E59FF;
    border-radius: 3px;
}

/* Remove estilos de scrollbar não utilizados */
.automations-list::-webkit-scrollbar,
.automations-list::-webkit-scrollbar-track,
.automations-list::-webkit-scrollbar-thumb {
    display: none;
}

/* Remove estilos não utilizados */
.message-section,
.dm-link,
.automation-form {
    display: none;
}

.post-card.selected {
    border: 2px solid #8E59FF;
    transform: translateY(-4px);
}

.post-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    padding: 4px 12px;
}

.automation-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #8E59FF;
    color: white;
    width: 20px;
    height: 20px;
    min-width: 20px;
    border-radius: 50%;
}

.automation-icon {
    font-size: 14px;
}

/* Estilos para o popup de automação */
.automation-popup .swal2-html-container {
    margin: 1em 1.6em 0.3em;
}

.swal2-automation-form .form-group {
    margin-bottom: 20px;
}

.swal2-automation-form label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-weight: 500;
}

.swal2-automation-form .swal2-input,
.swal2-automation-form .swal2-textarea {
    width: 100%;
    margin: 0;
    padding: 12px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 14px;
}

.swal2-automation-form .swal2-textarea {
    min-height: 120px;
    resize: vertical;
}

.swal2-popup.automation-popup {
    max-height: 90vh;
    overflow-y: auto;
}

.swal2-automation-form {
    max-height: 60vh;
    overflow-y: auto;
    padding-right: 10px;
}

.dm-link {
    color: #8E59FF;
    text-decoration: underline;
    word-break: break-all;
}

.message-section strong {
    display: block;
    margin-top: 12px;
    margin-bottom: 4px;
    color: #333;
}

.message-section p {
    background: #f8fafc;
    padding: 12px;
    border-radius: 8px;
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
}

/* Ajustes no popup */
.swal2-automation-form .form-group:not(:last-child) {
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 16px;
}

.swal2-automation-form .form-group label {
    font-size: 14px;
    color: #4a5568;
}

.swal2-automation-form .form-group input::placeholder,
.swal2-automation-form .form-group textarea::placeholder {
    color: #a0aec0;
}

.swal2-popup.automation-popup {
    max-height: 90vh;
    overflow-y: auto;
}

.swal2-automation-form {
    max-height: 60vh;
    overflow-y: auto;
    padding-right: 20px;
    text-align: left;
    margin-left: 20px;
    padding-left: 5px;
    
}

.swal2-container {
    padding: 16px;
}

.swal2-container.swal2-center {
    align-items: center;
    margin-top: 0px;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #8E59FF;
    width: 40px;
    height: 40px;
    transform: translate(-50%, -50%);
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.no-data-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 40px;
    color: #757575;
    transform: translate(-50%, -50%);
}

.status-controls {
    display: flex;
    align-items: center;
    gap: 12px;
}

.status-badge {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
}

.status-badge.active {
    background-color: #48BB78;
    color: white;
}

.status-badge.inactive {
    background-color: #A0AEC0;
    color: white;
}

/* Ajustes para o switch do Chakra UI */
.chakra-switch {
    --switch-track-width: 44px;
    --switch-track-height: 22px;
}

.chakra-switch__track {
    background-color: #A0AEC0 !important;
}

.chakra-switch[data-checked] .chakra-switch__track {
    background-color: #48BB78 !important;
}


.automation-card:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


/* Estilos para o popup de mensagens diretas */
.direct-messages-popup {
    max-height: 80vh;
    overflow-y: auto;
}

.direct-messages-container {
    background: #fff;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: -10px;
}

.direct-message {
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    padding: 12px;
    margin-bottom: 16px;
}

.dm-header {
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.dm-content {
    margin-bottom: 10px;
}

.dm-footer {
    border-top: 1px solid #e2e8f0;
    padding-top: 10px;
    margin-top: 10px;
}

.dm-field {
    margin-bottom: 8px;
    display: flex;
    align-items: flex-start;
    gap: 6px;
    font-size: 0.85em;
}

.dm-field strong {
    min-width: 140px;
    color: #4a5568;
}

.dm-icon {
    font-size: 1em;
    min-width: 20px;
    color: #8E59FF;
}

.dm-message {
    border-radius: 4px;
    margin-top: 2px;
    white-space: pre-wrap;
    font-size: 0.9em;
    color: #2d3748;
}

.dm-timestamp {
    font-size: 0.8em;
    color: #718096;
    margin-top: 4px;
    margin-left: 26px;
}

.dm-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 4px;
    margin-left: 2px;
}

.dm-tag {
    background: #8E59FF;
    color: white;
    padding: 2px 6px;
    border-radius: 3px;
    font-size: 0.75em;
}

/* Customização do SweetAlert para este popup específico */
.swal2-popup.direct-messages-popup {
    padding: 0;
}

.swal2-popup.direct-messages-popup .swal2-title {
    padding: 16px;
    margin: 0;
    font-size: 1.2em;
    border-bottom: 1px solid #e2e8f0;
}

.swal2-popup.direct-messages-popup .swal2-html-container {
    margin: 0;
    padding: 16px;
}

/* Esconder o botão de fechar */
.swal2-popup.direct-messages-popup .swal2-close {
    display: none;
}

.post-actions {
    margin-left: -10px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.view-messages-btn {
    background: transparent;
    color: #8E59FF;
    border: none;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
}

.view-messages-btn:hover {
    color: #7040FF;
    transform: scale(1.1);
}




@media (max-width: 1280px) {
    .automation-container {
        max-width: 1220px;
    }

    .post-selection-grid {
        grid-template-columns: repeat(4, 1fr);
        padding-left: 5px;
    }

    .automations-list {
        grid-template-columns: repeat(1, 1fr);
        padding: 16px;
    }

    .post-card {
        width: 265px;
    }

    .status-badge {
        font-size: 12px;
        margin-right: -5px;
    }

    .post-stats {
       
        padding: 0px;
        gap: 10px;
        margin-left: -10px;
    }

    .post-actions {
        margin-left: -5px;
        display: flex;
        align-items: center;
    }

    .view-messages-btn {
        margin-left: 10px;
    }
}

@media (max-width: 768px) {
    .automation {
        position: fixed;
        height: 100vh;
        width: 100%;
        overflow: hidden;
    }

    .automation-container {
        height: 100%;
        margin-top: 65px;
        overflow: hidden;
    }

    .automation-indicator {
        margin-top: 0;
    }

    .automation-header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        padding-bottom: 8px;
    }

    .automation-header-left {
        gap: 8px;
    }

    .automations-list {
        grid-template-columns: 1fr;
        padding: 12px;
        gap: 16px;
        height: calc(100vh - 345px);
    }

    .automation-stats {
        margin-top: -10px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        height: 60px;
        width: 100%;
        gap: 8px;
        margin-bottom: 15px;
    }

    .stat-card {
        flex: 1;
        padding: 6px;
        height: 45px;
        min-width: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2px;
        border-radius: 8px;
    }

    .stat-value {
        font-size: 14px;
        margin: 0;
        line-height: 1;
        white-space: nowrap;
    }

    .stat-label {
        font-size: 10px;
        white-space: nowrap;
        line-height: 1;
    }

    .posts-container {
        height: calc(100vh - 280px);
        padding: 12px;
        overflow: hidden;
    }

    .post-selection-grid {
        grid-template-columns: 1fr;
        padding-left: 22.5px;
        padding-top: 15px;
        gap: 20px;
        height: 100%;
        overflow-y: auto;
    }

    .post-card {
        height: 330px;
        width: 282.5px;
    }

    .post-image {
        height: 250px;
    }

    .post-footer {
        flex-direction: row;
        align-items: center;
    }   

    .post-id {
        font-size: 12px;
        padding: 2px 6px;
    }

    .automation-name {
        font-size: 14px;
    }

   

    .automation-content {
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }

    .keywords-section {
        width: 100%;
    }

    .stats-section {
        flex-direction: column;
        gap: 8px;
        width: 100%;
        padding: 8px;
    }

    .stat-item {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 5px 
    }

    .stat-value {
        margin-bottom: 0;
        font-size: 14px;
    }

    .stat-label {
        font-size: 10px;
    }

    .swal2-automation-form {
        margin-top: -10px;
        height: 300px;
        width: 295px;
        margin-left: 10px;  
        overflow-y: scroll;
    }

    .custom-swal-title {
        font-size: 20px !important; 
    }

    .post-actions {
        margin-left: 0px;
        display: flex;
        align-items: center;
    }

    .view-messages-btn {
        margin-left: 7.5px;
    }

    .post-stats {
       
        padding: 0px;
        padding-top: 15px;
        padding-bottom: 15px;
        gap: 0px;
        margin-left: -16.5px;
    }

    .direct-messages-popup {
        width: 95% !important;
        height: 75% !important;
    }

    .direct-messages-container {
        margin-top: -5px;
    }

    .direct-message {
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 8px;
        background: #f8f9fa;
        width: 100%;
    }
    .dm-header {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .dm-timestamp {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding-right: 20px;
    }
}

