.swiper-button-next,
.swiper-button-prev {
    background: transparent;
    color: #757575;
    /* Cor das setas. */
    width: 50px;
    /* Largura das setas. */
    height: 50px;
    /* Altura das setas. */
    transform: scale(2.0);
}

.slideContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.categoryTitle-t {
    width: 100%;
    /* Estender o título para toda a largura do container */
    text-align: left;
    margin-top: 30px;
    /* Alinhar o texto à direita */
    margin-bottom: 1.5rem;
    color: #757575;
    font-weight: bold;
    font-size: 24px;
}

.containerExter {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    justify-content: 'center';
    height: '100%'
}

html,
body,
#root {
    height: 100vh;
    margin: 0;
    padding: 0;
}

.swiper {
    height: 100vh;
    width: 100%;
}

.swiper-container {
    height: 100vh;
    background-color: #f8f9fa
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    box-sizing: border-box;
    padding: 40px 60px;
    height: 100vh;
    background-color: #f8f9fa;
}

.gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2rem;
    width: 100%;
    height: 100%;
}

.gridItem {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* ... outros estilos ... */

/* Cores do texto das categorias e dos botões */
.colorBlue {
    color: #3f729b;
    /* Azul Instagram */
}

.colorGreen {
    color: #4CAF50;
    /* Verde Instagram */
}

.colorYellow {
    color: #FECB2E;
    /* Amarelo Instagram */
}

.colorOrange {
    color: #F58529;
    /* Laranja Instagram */
}

.colorRed {
    color: #fa1427;
    /* Vermelho Instagram */
}

.colorPink {
    color: #fe8399;
    /* Rosa Instagram */
}

.colorDefault {
    color: #333;
    /* Cor de texto padrão */
}

.nicheButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid #CCCCCC;
    /* Altera a cor da borda dos botões */
    font-size: 1em;
    text-align: center;
    padding: 100px;
    background: transparent;
    transition: all 0.3s ease;
    color: #757575;
    /* Altera a cor do texto dos botões */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border-radius: 10px;
}

.googleButton {
    display: inline-flex;
    align-items: center;
    /* position:right;*/
    justify-content: center;
    box-sizing: border-box;
    padding: 0 16px;
    min-width: 150px;
    height: 36px;
    border: none;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;

    /* Adiciona um leve efeito de sombra ao botão */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.googleButton:hover {
    /* Adiciona um efeito de hover, escurecendo levemente o botão */
    filter: brightness(95%);
}

.googleButton:active {
    /* Adiciona um efeito de clique, escurecendo ainda mais o botão */
    filter: brightness(75%);
}

.googleButtonTxt {
    display: inline-flex;
    align-items: center;
    /* position:right;*/
    justify-content: center;
    box-sizing: border-box;
    padding: 0 16px;
    min-width: 150px;
    height: 36px;
    border: none;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;

    /* Adiciona um leve efeito de sombra ao botão */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.googleButtonTxt:hover {
    /* Adiciona um efeito de hover, escurecendo levemente o botão */
    filter: brightness(95%);
}

.googleButtonTxt:active {
    /* Adiciona um efeito de clique, escurecendo ainda mais o botão */
    filter: brightness(75%);
}


.lastButton {
    display: inline-flex;
    align-items: center;
    /* position:right;*/
    justify-content: center;
    box-sizing: border-box;
    padding: 0 16px;
    min-width: 180px;
    height: 36px;
    border: none;
    border-radius: 80px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;

    /* Adiciona um leve efeito de sombra ao botão */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.lastButton:hover {
    /* Adiciona um efeito de hover, escurecendo levemente o botão */
    filter: brightness(95%);
}

.lastButton:active {
    /* Adiciona um efeito de clique, escurecendo ainda mais o botão */
    filter: brightness(75%);
}


.my-swal-alert .swal2-checkbox input {
    position: absolute;
    background-color: #ffffff;
}

.my-swal-html-container {
    overflow-y: hidden !important;
    /* Oculta a barra de rolagem vertical */
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.blink {
    animation: blink 2s linear infinite;
    color: #757575;
}

.selectButton {
    display: block;
    margin-top: 20px;
    /* Adiciona margem acima do botão */
    width: 200px;
    height: 40px;
    margin: 20px auto;
    background-color: transparent;
    border: 2px solid #CCCCCC;
    border-radius: 5px;
    color: #757575;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.selectButton:hover {
    background: #f2f2f2;
    /* Muda a cor de fundo ao passar o mouse */
}

.container {
    display: flex;
    align-items: center;
    /* Centraliza verticalmente */
    gap: 10px;
    /* Adiciona espaço entre os elementos */
    margin-bottom: 10px;
}

.myCustomLabel {
    display: flex;
    align-items: center;
    /* Centraliza verticalmente os itens dentro da label */
    gap: 5px;
    /* Espaço entre a imagem e o texto */
}

.my-swal .swal2-image {
    width: 100%;
    /* Ocupar toda a largura do container */
    height: auto;
    /* Mantenha a proporção */
    max-height: 200px;
    /* Ajuste conforme necessário */
    object-fit: cover;
    /* Semelhante ao background-size: cover para <img> */
    display: block;
    margin: 0 auto;
    /* Centralizar horizontalmente */
}

.divTextArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90%;
    margin-top: 20px;
}

/*.textAreaSpace {
    flex: 1;
    width: 80%;
    font-size: 16px;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    font-family: Arial, sans-serif;
    padding: 10px 50px;
    margin-bottom: 10px;
} */


.textAreaSpaceDin {

    width: 100%;
    /* Ocupa toda a largura do container pai */
    min-height: 80px;
    /* Altura mínima para a área de texto */
    padding: 10px 40px 10px 40px;
    /* Espaçamento interno para a área de texto */
    margin-top: 5px;
    /* Espaço entre a label e a textarea */
    border-radius: 10px;
    /* Bordas arredondadas para a textarea */
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 16px;
    /* Tamanho da fonte para a área de texto */
    font-family: Arial, sans-serif;
    /* Fonte para a área de texto */
}

.slidesContainer {
    border-radius: 10px;
    height: 90%;
    overflow-y: auto;
    padding: 10px;
    margin-top: 0px;
    width: 80%;
    margin-bottom: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.accordion-item {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    margin-bottom: 8px;
    overflow: hidden;
    background-color: white;
}

.accordion-header {
    padding: 15px;
    background-color: #f8fafc;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #757575;
    font-size: 16px;
    transition: all 0.3s ease;
}

.accordion-header:hover {
    background-color: #f1f5f9;
}

.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
    background-color: white;
    padding: 0 15px;
}

.accordion-content.open {
    max-height: 500px;
    padding: 15px;
}

.accordion-icon {
    transition: transform 0.3s ease;
}

.accordion-icon.open {
    transform: rotate(180deg);
}

.textAreaSpaceDin {
    width: 100%;
    min-height: 80px;
    padding: 10px;
    margin-top: 10px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-family: Arial, sans-serif;
    resize: vertical;
}

.textAreaSpaceDin:focus {
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
    outline: none;
}

.editable-slide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Alinha os elementos ao início do container */
    width: 100%;
    margin-bottom: 20px;
    /* Espaço entre cada bloco de edição, se necessário */
}

.lblEditText {
    color: #757575;
    font-size: 16;
    margin-left: 40;
}


.buttonGroup {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 10px;
    gap: 20px;
}

.buttonGroupSave {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 10px;
    gap: 20px;
}

.buttonGroupImg {
    width: 1184px;
    display: flex;
    justify-content: center;
    margin-left: 70px;
    margin-right: 70px;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 10px;
}

.btnNew {
    margin-top: 0px;
    width: 560px;
    margin-right: 0px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

.userInfoDesktop {
    display: block;
    font-size: 17px;
}

.userInfoMobile {
    display: none;
    font-size: 14px;
}

.textContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 6%;
    align-items: center;
    background-color: white;
    padding: 35px 25px 25px 25px;
    border-radius: 16px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
    max-width: 640px;
    margin: 0 auto;
}

.textHeadline {
    color: #757575;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
}

.headlines-grid-container {
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 22.5px;
    max-width: 100%;
    padding: 25px;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 520px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.headlines-button {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 220px;
    padding: 16px;
    background-color: white;
    border: 2px solid #e2e8f0;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
}

.headlines-button:hover {
    transform: translateY(-2px);
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
}

.textareaContainer {
    position: relative;
    width: 80%;
    margin: 10px;
    display: flex;
    flex-direction: column;
}

.editButton {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 36px;
    height: 36px;
    background-color: #8E59FF;
    color: white;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.editButton:hover {
    background-color: #6d47cc;
    transform: scale(1.1);
}

.buttonStyle {
    background-color: #CCCCCC;
    color: white;
    width: 15%;
}

.carouselBox {
    margin-top: 77.5px;
    /*background-color: #fff;*/
    padding: 20px;
    height: 520px;
    border-radius: 10px;
    width: 1250px;
    /*box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);*/
    display: flex;
    flex-direction: column;
    /* Dividir horizontalmente */
    align-items: stretch;
    /* Ambas as colunas esticam para ocupar toda a altura */
    /* ... outras propriedades ... */
}

.carouselBoxStories {
    margin-top: 0px;
    width: 1100px;
    height: 500px;
}

.carouselContent {

    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    margin-top: -5px;
}

.selectionArea {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.containerComboBox {
    width: 80%;
    margin-bottom: 20px;
    display: flex;
    gap: 40px;
}

.previewImage {
    display: flex;
    width: 800px;
    height: auto;
    justify-content: center;
    align-items: center;
}

.previewImage img {
    width: 300px;
    height: auto;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}


.previewImageStories {
    display: flex;
    width: 400px;
    height: auto;
    justify-content: center;
    align-items: center;

}


.previewImageStories img {
    width: 200px;
    height: auto;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.swal-input {
    border-radius: 5px;
    margin-top: 10px;
    width: 95%;
    height: 45px;
    margin-bottom: 5px;
    font-family: Arial;
    font-size: 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: 2px solid #767676;
    padding: 10px;
}

.colorGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-y: scroll;
    width: 100%;
    height: 213px;
    margin-left: 0px;
    /* Ajuste conforme necessário */
    margin-bottom: 10px;
}


.colorGroupStories {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    height: 210px;
}

.btnSelect {
    margin-top: 0px;
    display: flex;
    justify-content: flex-end;
}

.btnSelectStories {
    position: relative;
    margin-top: 90px;
    width: 780px;
}

.radioLay {
    margin-top: 15px;
    margin-right: 42px;
    max-width: 300px;
}

.radio-group-footer {
    position: absolute;
    margin-left: 72px;
    margin-top: 30px;
    width: 500px;
    z-index: 10;
    /* Outros estilos que desejar para alinhar o RadioGroup corretamente */
}


.uploadContainer {
    display: flex;
    width: 89.5%;
    height: 320px;
    /* Ajuste a altura conforme necessário */
    /* Apenas para visualização */
    gap: 20px;
}

.IAContainer {
    display: flex;
    width: 89.5%;
    height: 320px;
    gap: 20px;
}


.fileSizeNote {
    font-size: small;
}

.imagePreviewArea {
    flex: 1;
    /* Ocupa 50% da largura */
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    /* Para manter a imagem dentro da área de visualização */
    border-right: 0px solid #ccc;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    /* Apenas para visualização */
}

.imagePreview {
    max-width: 100%;
    max-height: 85%;
    margin-top: 0px;
    object-fit: contain;
}


.imagePreviewOwn {
    max-width: 100%;
    max-height: 85%;
    margin-top: 0px;
    object-fit: contain;
}


.imagePreviewArea.selected {
    border: 5px solid #8E59FF;
    border-radius: 5px;
}


.fileInputContainer {
    margin-top: 0px;
    flex: 1;
    /* Ocupa os restantes 50% da largura */
    display: flex;
    align-items: center;
    /* Centraliza verticalmente */
    justify-content: center;
    /* Centraliza horizontalmente */
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.fileInput {
    padding: 10px 20px;
    /* Ajuste o padding conforme necessário */
    cursor: pointer;
    /* Estilo do cursor para indicar que é clicável */
    /* Outras personalizações de estilo aqui */
}

.customFileButton {
    display: flex;
    align-items: center;
    /* Centraliza verticalmente */
    justify-content: center;
    /* Centraliza horizontalmente */
    gap: 10px;
    /* Espaço entre o ícone e o texto */
    background-color: #CCCCCC;
    color: white;
    border: none;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 20px;
    transition: background-color 0.3s;
    /* Adicione mais estilos conforme necessário */
}

.customFileButton:hover {
    /* Adiciona um efeito de hover, escurecendo levemente o botão */
    filter: brightness(95%);
}

.customFileButton:active {
    /* Adiciona um efeito de clique, escurecendo ainda mais o botão */
    filter: brightness(75%);
}


.buttonStepper {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 1180px;
    margin-left: 88px;
    margin-right: 88px;
    margin-bottom: 70px;
    bottom: 0;
}

.imagesContainer {
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 330px;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 15px;
}


.divImagesAjs {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
}

.divImagesAjs.selected {
    border: 3px solid #8E59FF;
    /* ou a cor que preferir */
    box-shadow: 0 0 10px #8E59FF;
    /* para adicionar um destaque extra */
}


.thumbnailImage {
    width: 220px;
    height: 220px;
    object-fit: cover;
    transition: opacity 0.3s;
}

.buttonFinal {

    width: 300px;
    display: flex;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: -60px;
    gap: 20px;
}

.buttonFinalStories {
    width: 300px;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 25px;
    gap: 25px;
}

.outerContainer {
    margin-top: -60px;
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 80%;
    justify-content: space-between;
    align-items: center;
    

}

.innerContainer {

    margin-top: 0px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
    height: 74%;
    gap: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-right: 60px;
    /* Espaço para os botões */
}

.innerContainer4x5 {

    margin-top: 0px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
    height: 90%;
    gap: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-right: 60px;
    /* Espaço para os botões */
}

.firstImage,
.subsequentImage {
    width: 350px;
    height: 350px;
    object-fit: cover;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
    /* Impede que as imagens encolham */
}

.firstImage4x5,
.subsequentImage4x5 {
    width: 300px;
    height: 375px;
    object-fit: cover;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
}

.subsequentImage {
    margin: 0;
}

.floatingButtonsContainer {
    position: fixed;
    right: 20%;
    top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    margin-top: 0;
    margin-left: 0;
}

.floatingButtonsContainerStories {

    position: fixed;
    top: 100px;
    right: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    margin-top: -15px;

}

.floatingButton {
    margin-bottom: 15px;
    /* Espaçamento entre os botões */
    height: 60px;
    /* Tamanho do botão */
    width: 60px;
    /* Tamanho do botão */
    border-radius: 50%;
    /* Cria bordas arredondadas */
    background-color: #3f3d454a;
    /* Cor de fundo do botão */
    color: white;
    /* Cor do ícone ou texto */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
}

.outerContainerStories {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}



.innerContainerStories {
    margin-top: 40px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; /* Mudado para flex-start */
    align-items: center;
    width: 60%;
    height: 80%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0 20px; /* Adicionado padding lateral */
    gap: 20px;
}

.slideImage.storyImage:first-child {
    margin-left: 0;
}

.slideImage.storyImage {
    margin-left: 10px;
    margin-top: 0px; /* Margem negativa no topo e espaço entre imagens */
    width: 253.82px;
    height: 451.25px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover;
    flex-shrink: 0;
}


.bottomLoading {
    bottom: 82.4%;
}

.bottom410 {
    bottom: 84.95%;
}

.bottom310 {
    bottom: 81%;
}

.captionContainer {
    width: 95%;
    height: 130px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
}


.blinking-text {
    animation: blink 1.2s linear infinite;
}

.loading-text-2 {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    /* Ajuste o tamanho conforme necessário */
    height: 10%;
    /* Garantir que a área de texto ocupe toda a altura disponível */
    text-align: center;
    /* Ajuste conforme necessário */
}


/* ...resto do CSS... */

.footer-btn-wrapper button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    width: 150px;
    /* ajuste conforme a largura desejada */
    height: 50px;
    /* ajuste conforme a altura desejada */
    color: #757575;
    font-size: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: none;
    /* garante que não há sombra */
    transition: transform 0.3s;
    /* para um efeito suave ao pressionar */
}

.footer-btn-wrapper {
    position: absolute;
    bottom: 22px;
    /* margem do rodapé */
    left: 50%;
    transform: translateX(-50%);
}


.footer-btn-wrapper button:hover .buttonIcon,
.footer-btn-wrapper button:hover span {
    filter: brightness(1.8);
    /* 1 é o normal, >1 torna mais claro */
}

.footer-btn-wrapper button:focus {
    box-shadow: none;
    /* remove o foco/sombra ao clicar */
}

.top-btn-wrapper button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    width: 200px;
    /* ajuste conforme a largura desejada */
    height: 50px;
    /* ajuste conforme a altura desejada */
    color: #757575;
    font-size: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: none;
    /* garante que não há sombra */
    transition: transform 0.3s;
    /* para um efeito suave ao pressionar */
}

.top-btn-wrapper {
    position: absolute;
    top: 45px;
    /* margem do rodapé */
    left: 50%;
    transform: translateX(-50%);
}


.top-btn-wrapper button:hover .buttonIcon,
.top-btn-wrapper button:hover span {
    filter: brightness(1.8);
    /* 1 é o normal, >1 torna mais claro */
}

.top-btn-wrapper button:focus {
    box-shadow: none;
    /* remove o foco/sombra ao clicar */
}


.pexelsImg {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.image-container {
    position: absolute;
    top: 7px;
    left: 70px;
    padding: 0;
    background-color: transparent;
    /* border-color: #757575;
    border: 2px solid #CCCCCC;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);*/

}

.image-container_01 {
    position: absolute;
    left: 20px;
    padding: 0;
    background-color: transparent;
}



.slide-image {
    width: 140px;
    /* ou qualquer outro valor que funcione para você */
    height: auto;
}


.star-rating {
    font-size: 0;
    direction: rtl;
    margin-top: 15px;
}

.star-rating input[type="radio"] {
    display: none;
}

.star-rating label {
    font-size: 40px;
    padding: 0;
    margin-right: 15px;
    /* Espaçamento entre as estrelas */
    cursor: pointer;
    color: #e0e0e0;
    /* Cor das estrelas apagadas */
    transition: color 0.2s;
}

.star-rating label:hover,
.star-rating label:hover~label,
.star-rating input:checked~label {
    color: gold;
    /* Cor das estrelas acesas */
}

.my-textarea {

    width: 90%;
    height: 130px;
    margin-top: 25px;
    font-family: Arial;
    font-size: 16px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #767676;
    padding: 10px;

}

.my-textarea2 {
    width: 100%;
    height: 65px;
    margin-top: 25px;
    font-family: Arial;
    font-size: 16px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #767676;
    padding: 10px;
}

.menu-icon {
    font-size: 45px;
    margin-top: 5px;
    cursor: pointer;
    color: #757575;
}


.labelTopic {
    margin-top: 10px;

}

.labelTopicFirst {
    margin-top: 0px;

}


.slcSlides {
    min-width: 140px !important;
    color: #757575;
    background-color: white;
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    /* transition: all 0.3s ease; */
}

.slcSlides:hover {
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
    outline: none;
}

.slcSlides:focus {
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
    /*  outline: none;*/
}

.slcSlides option {
    background-color: #ffffff;
    color: #757575;
    padding: 8px;
}



.slcSlidesLay {
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}


.inputFocusTxt {
    border-color: transparent;
    font-size: 16px;
    height: 37px;
    border-width: 1;
    border: 2px solid #e2e8f0;
    border-radius: 5px;
    /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);*/
    width: 50%;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0px;
    /*margin-bottom: 30px; */
    padding: 10px;
}

.inputFocusTxt:focus {
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
    outline: none;
}

.inputAreaTxt {
    border-width: 1;
    border-radius: 5px;
    /*box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);*/
    width: 100%;
    margin-left: 0px;
    border: 2px solid #e2e8f0;
    height: 60px;
    padding: 5px 0px 0px 10px;
}

.inputAreaTxt:focus {
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
    outline: none;
}


.inputAreaTxtFullWidth {
    flex-grow: 1;
    /* Faz o textarea crescer para ocupar o espaço disponível */
    width: 100%;
    /* Sobrepor qualquer largura específica definida anteriormente */
}


.dividerFirst {
    margin-bottom: 10px;
    margin-left: 10px;
}

.dividerFirstStry {
    margin-bottom: 10px;
    margin-left: 10px;
}

input::placeholder {
    color: #999;
    /* Cor do placeholder */
    font-style: italic;
    /* Estilo da fonte */
    opacity: 0.6;
    /* Certifique-se de que o placeholder é sempre visível */
}

textarea::placeholder {
    color: #999;
    /* Cor do placeholder */
    font-style: italic;
    /* Estilo da fonte */
    opacity: 0.6;
    /* Certifique-se de que o placeholder é sempre visível */
}

.publicContainer {

    background-color: '#fff';
    padding: 35px 25px 25px 25px;
    border-radius: 16px;
    width: 640px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
    margin: 0 auto;

}

.buttonIcon {
    font-size: 25px;

}


.spinner_4 {
    border: 4px solid rgba(0, 0, 0, 0.1);
    /* Cor de fundo do spinner */
    border-radius: 50%;
    border-top: 4px solid #8E59FF;
    /* Cor do spinner */
    width: 40px;
    margin-top: 0px;
    /* Tamanho do spinner */
    height: 40px;
    /* Tamanho do spinner */
    animation: spin 2s linear infinite;
}



.buttonContent {
    display: flex;
    /* Alinha o ícone e o texto na horizontal */
    align-items: center;
    /* Centraliza verticalmente o ícone e o texto */
    justify-content: center;
    /*margin-right: 10px;   */
    margin-left: 7.5px;
}

.googleButtonNew {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 180px;
    /* Reduzido de um valor maior */
    height: 42px;
    background-color: #8E59FF;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(142, 89, 255, 0.2);
}

.googleButtonNew:hover {
    /* Adiciona um efeito de hover, escurecendo levemente o botão */
    filter: brightness(95%);
}

.googleButtonNew:active {
    /* Adiciona um efeito de clique, escurecendo ainda mais o botão */
    filter: brightness(75%);
}

.categoryTitle {
    text-align: left;
    padding-bottom: 50px;
    margin-bottom: 0px;
    width: 80%;
    margin-top: -20px;
    font-size: 24px;
    padding-left: 22px;
    color: #2d3748;
    font-weight: 600;
}


.categoryTitle-box {
    font-size: 24px;
    color: #2d3748;
    font-weight: 600;
    text-align: center;
    margin: 0;
}

.categoryTitleTema {
    margin-top: -20px;
    margin-bottom: 20px;
    width: 100%;
    padding-left: 22px;
    color: #757575;
    font-weight: bold;
    font-size: 24px;
}

.divMaster {
    display: 'flex';
    flex-direction: 'row';
    height: 565px;
    justify-content: 'space-between';
    margin-top: 20px;
    margin-bottom: 20px;
}


.divMaster.template-style {
    height: 560px;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 20px;
}

.divMaster.theme-style {
    height: 565px;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 20px;
}



.outcome-grid-container {

    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 22.5px;
    max-width: 100%;
    padding: 25px;
    padding-top: 2.5px;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 500px;

}


.outcome-button {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 220px;
    padding: 16px;
    background-color: white;
    border: 2px solid #e2e8f0;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
}

.outcome-button:hover {
    transform: translateY(-2px);
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
}



.button-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 12px;
}

.emoji-icon {
    display: inline-block;
    font-size: 28px;
    margin-bottom: 4px;
}

.text-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title {
    font-size: 14px;
    font-weight: 600;
    color: #374151;
    line-height: 1.25;

}

.summary {
    font-size: 14px;
    color: #757575;
    line-height: 1.5;
    margin: 0;
}


.summaryText {
    font-size: 16px;
    color: #757575;
    line-height: 1.45;
    text-align: center;
}


.tabsContainer {
    margin-top: -30px;
    /* Ajuste este valor conforme necessário */
}

.tabsList {
    margin-left: 85px;
    margin-right: 85px;
    display: flex;
    gap: 7px;

}



.tabsContainer .chakra-tabs__tab {
    background: #8E59FF;
    /* ou qualquer cor de fundo desejada */
    box-shadow: none;
    /* remove sombras */
    border: 1px solid #CBD5E0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /* ou qualquer outra cor de borda desejada */
}

.tabsContainer .chakra-tabs__tab {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    color: #757575;
    background: #ffffff;
    transition: all 0.3s ease;
}

.tabsContainer .chakra-tabs__tab[aria-selected="true"] {
    background-color: #8E59FF;
    color: white;
    box-shadow: 0 2px 4px rgba(142, 89, 255, 0.2);
}

.inputAreaEspecial {
    width: 100%;
    height: 100%;
    padding: 1rem;
    padding-right: 3rem;
    border-radius: 5px;
    resize: none;
    border: 2px solid #e2e8f0;
    /* Adicionando borda padrão */
    /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);*/
    transition: all 0.2s ease;
    /* Adicionando transição suave */
}

.inputAreaEspecial:focus {
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
    outline: none;
    /* Move o outline: none para aqui */
}


/* CSS para a tab não selecionada */

.tabsContainerEnter .tab-without-image {
    color: #757575;
    color-scheme: purple;
}

.tabsContainerEnter .chakra-tabs__tab {
    background: white;
    /* ou qualquer cor de fundo desejada */
    box-shadow: none;
    /* remove sombras */
    border: 1px solid #CBD5E0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /* ou qualquer outra cor de borda desejada */
}

.tabsContainerEnter .chakra-tabs__tablist {
    border-bottom: 1px solid #CBD5E0;
    position: relative;
    /* Necessário para o z-index funcionar */
    z-index: 100;
    /* Torna o tablist acima dos outros elementos */
}

.tabsContainerEnter .chakra-tabs__tab--selected {
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
        0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    /* para aplicar sombra apenas no tab ativo */
}

.tabsContainerEnter .chakra-tabs__tab:not([aria-selected="true"]) {
    /* border: none;*/
    border-left: none;
    border-right: none;
    border-top: none;
    /* Remove a borda das abas não selecionadas */
}


.tabSilverDesign {

    width: 94%;
    /* Garantir que o container ocupe toda a largura disponível */
    box-sizing: border-box;
    /* Inclui padding e borda no cálculo da largura */
    overflow-x: auto;
    /* Permite rolagem horizontal, caso necessário */

}


.tabSilverDesign .chakra-tabs__tab {
    background: white;
    /* ou qualquer cor de fundo desejada */
    box-shadow: none;
    /* remove sombras */
    border: 1px solid #CBD5E0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /* ou qualquer outra cor de borda desejada */
}


.tabSilverDesign .chakra-tabs__tab {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    color: #757575;
    background: #ffffff;
    transition: all 0.3s ease;
}

.tabSilverDesign .chakra-tabs__tab:hover {
    background: #f8fafc;
}

.tabSilverDesign .chakra-tabs__tab[aria-selected="true"] {
    background-color: #8E59FF;
    color: white;
    box-shadow: 0 2px 4px rgba(142, 89, 255, 0.2);
}


.firstEnter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    padding: 0;
    /*margin: 10 auto; */
    margin-top: 45px;
}


.btnPromo {
    margin-bottom: -80px;
    margin-top: 40px;
}

.btnPromo2 {
    margin-bottom: -55px;
    margin-top: 20px;

}

.stkBegin {

    margin-left: 10px;
    margin-bottom: 20px;
    padding-right: 117.5px;

}

.stkBegin.zero-margin {
    margin-bottom: 0px;
}


.stkBegin.spaceTwo {
    padding-right: 105px;
}

.grade {
    /* Grid básico */
    display: grid;
    grid-template-columns: repeat(0.5, 1fr);
    gap: 0rem;
    width: 100%;
    /* Card container */
    max-width: 31.2rem;
    /* equivalente ao max-w-2xl */
    max-height: 400px;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    padding: 0.5rem;
}

/* Container principal das headlines */

.headline-grid-container {

    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 22.5px;
    max-width: 100%;
    padding: 25px;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 520px;

}

.headline-button {
    display: flex;
    flex-direction: column;
    width: 220px;
    height: 220px;
    padding: 16px;
    background-color: white;
    border: 2px solid #e2e8f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.headline-button:hover {
    transform: translateY(-2px);
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
}


/* Container do conteúdo dentro do botão */
.headlines-section__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}


/* Título da headline */
.headlines-section__title {
    font-weight: bold;
    font-size: 1.1rem;
    line-height: 1.2;
    color: #333;
}


/* Texto da headline */
.headlines-section__text {
    font-size: 1rem;
    color: #666;
    line-height: 1.4;
}

.templateContainer:hover {
    transform: scale(1.02);
    /* Reduzido um pouco o scale no hover */
    transition: transform 0.2s ease;
}

.templateGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 10px;
    max-height: 460px;
    /* Aumentado para dar mais espaço vertical */
    max-width: calc(100% - 20px);
    /* Ajuste para evitar conflito com a barra de rolagem */
    overflow-y: auto;
    padding: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    margin-right: 0px;
    /* Adiciona margem à direita para a barra de rolagem */
}

.templateBox {
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    aspect-ratio: 4/5;
    /* Alterado para proporcionar mais altura vertical */
    background: #8E59FF;
    min-height: 50px;
    /* Altura mínima garantida */

}

.templateContainer {
    position: relative;
    width: 100%;
    height: calc(100% - 75px);
    /* Aumentado o espaço reservado para o título */
}


.templateImage {
    width: 110%;
    height: 110%;
    object-fit: cover;
    transition: transform 0.3s ease;
}


.slideContainer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.templateImage {
    width: 110%;
    height: 110%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.navButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #8E59FF;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.navButton span {
    display: block;
    margin-top: -3px;
    /* Ajuste fino se necessário */
}

.prevButton {
    left: 5px;
}

.nextButton {
    right: 5px;
}

.templateName {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background: #fff;
    font-size: 14px;
    font-weight: bold;
    height: 70px;
    align-items: center;
    /* Centraliza verticalmente */
    justify-content: center;
    /* Centraliza horizontalmente */
    display: flex;
}

.navButton:hover {
    background: rgba(255, 255, 255, 0.9);
    color: #8E59FF;
}

.templateBox:hover .navButton {
    opacity: 1;
}

.previewContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    width: 88%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: #fff;
}

.previewContent {
    padding: 20px;
    border-radius: 8px;
    transition: all 0.3s ease;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}


.previewContent h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    transition: color 0.3s ease;
}

.previewContent p {
    font-size: 14px;
    line-height: 1.5;
    transition: color 0.3s ease;
}

.iconEye {

    margin-left: 12px;
    font-size: 20px;

}

.titleWithIcon {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.titleWithIcon h4 {
    margin: 0;
}



.tablist_control_design {
    display: flex;
    /* Alinha as abas horizontalmente */
    justify-content: flex-start;
    /* Alinha todas as abas à esquerda */
    gap: 0px;
    /* Remove espaço entre as abas */
    padding: 0;
    /* Remove padding interno */
    margin: 0;
    /* Remove margens ao redor */
    margin-left: 15px;
    border-bottom: 1px solid #CBD5E0;
    /* Linha abaixo das abas */
    max-width: calc(100% - 30px);
    /* Garante que o container ocupe toda a largura */
    box-sizing: border-box;
    /* Inclui padding e bordas no cálculo da largura */
}

.tab_control_design {
    padding: 10px 20px;
    /* Espaçamento interno */
    background-color: #fff;
    /* Fundo branco */
    color: #757575;
    /* Cor do texto */
    cursor: pointer;
    /* Indica que é clicável */
    border: 1px solid transparent;
    /* Borda transparente para manter o alinhamento */
    border-bottom: none;
    /* Remove a borda inferior */
    border-top-left-radius: 5px;
    /* Bordas arredondadas no topo */
    border-top-right-radius: 5px;
    box-shadow: none;
    /* Remove as sombras */
    transition: background-color 0.3s ease, color 0.3s ease;
    /* Suaviza as mudanças */
    margin: 0;
    /* Remove margens ao redor da aba */
    height: 25px;
    font-size: 14px;
}

.tab_control_design[aria-selected="true"] {
    border: 1px solid #CBD5E0;
    /* Adiciona borda apenas na aba ativa */
    background-color: white;
    /* Fundo branco para aba ativa */
    color: #8E59FF;
    /* Cor do texto da aba ativa */
    border-bottom: 2px solid white;
    /* Remove a borda inferior */
    border-top-left-radius: 5px;
    /* Bordas arredondadas no topo esquerdo */
    border-top-right-radius: 5px;
    /* Bordas arredondadas no topo direito */
    border-bottom-left-radius: 0;
    /* Remove arredondamento na parte inferior */
    border-bottom-right-radius: 0;
    /* Remove arredondamento na parte inferior */
    box-shadow: none;
    /* Remove sombras */
    font-size: 14px;
}

.tabPanels_control_design {
    width: 100%;
    /* Ocupa toda a largura do container */
    padding: 0px;
    /* Ajusta o espaçamento interno */
    box-sizing: border-box;
}

.tabpanel_control_design {
    width: 100%;
    /* Ocupa toda a largura */
    padding: 10px;
    /*background-color: #fff;*/
    /* Mantém o painel branco ou conforme necessário */
    box-sizing: border-box;

}

.style-customization-container {
    padding: 10px;
    padding-left: 14px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    width: 98%;
    margin-left: 5px;
}

.header-with-checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.style-label {
    font-size: 14px;
    color: #757575;
}

.color-grid {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
}

.color-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.color-name {
    font-size: 12px;
    color: #757575;
}

.color-picker {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #ddd;
    cursor: pointer;
    transition: transform 0.2s;
}

.color-picker:hover {
    transform: scale(1.05);
}

.color-grid {
    margin-top: -10px;
}

.font-selection {
    margin-top: -10px;
}

.font-row {
    display: flex;
    gap: 20px;
    align-items: flex-start;
}

.font-group {
    flex: 1;
    max-width: 200px;
}

.font-label {
    display: block;
    font-size: 12px;
    color: #757575;
    margin-bottom: 5px;
}

.font-select {
    width: 100%;
    padding: 6px;
    border: 1px solid #e2e8f0;
    background-color: white;
    border-radius: 5px;
    font-size: 13px;
    color: #757575;
}

.font-select:hover {
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
    outline: none;
}

.font-select:focus {
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
    outline: none;
}

.stk {
    margin-top: 16px;
    margin-bottom: 10px;
}

.catDesing {
    margin-top: 10px;
    margin-bottom: -100px;
    width: 100%;
    padding-left: 22px;
    color: #2d3748;
    font-weight: 600;
    font-size: 24px;
}



.templateBox.selected {

    /*  box-shadow: 0 0 5px rgba(32, 198, 54, 0.5); */
    box-shadow: 0 0 0 5px rgba(142, 89, 255, 1);
}

@media (max-width: 1280px) {

    /* Tablet */
    .divAreaImage {
        width: 100%;
    }

    .inputAreaTxtFullWidth {
        width: 40%;
    }

    .buttonGroupImg {
        margin-left: 66px;
        width: 88.2%;
    }

    .btnNew {
        justify-content: flex-start;
    }

    .inputFocusTxt {
        width: 50%;
    }

    .uploadContainer {
        width: 88%;
        display: flex;
        justify-content: flex-end;
        margin-left: -2px;
    }

    .IAContainer {
        width: 88%;
        display: flex;
        justify-content: flex-end;
        margin-left: -2px;
    }

    .imagesContainer {
        margin-left: 2px;
    }

    .divImagesAjs {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 16.8px;
        margin-right: 16.8px;
    }

    .buttonStepper {
        width: 986px;
        margin-bottom: 100px;
    }

    .captionContainer {
        width: 95%;
    }

    .radioLay {
        margin-top: 5px;
        margin-right: 62px;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .headline-button {

        width: 200px;

    }

    .outcome-button {

        width: 240px;

    }

    .pexelsImg {

        bottom: -100px;

    }


    /* Título da headline */
    .headlines-section__title {
        font-size: 1rem;
    }

    /* Texto da headline */
    .headlines-section__text {
        font-size: 0.9rem;
    }


    .carouselBox {
        width: 1150px;
    }

    .carouselBoxStories {
        margin-top: 0px;
        width: 1000px;
    }

    .btnSelectStories {
        position: relative;
        margin-top: 90px;
        width: 640px;
    }

    .images-grid {
        width: 1150px;
        height: 80%;
    }

    .image-wrapper {
        width: 242px;
    }

    .grid-content {
        grid-template-columns: repeat(4, minmax(242px, 1fr));
        gap: 10px;
    }

    .floatingButtonsContainerStories{
        right: 300px;
    }

}

@media (hover: hover) and (pointer: fine) {
    .nicheButton:hover {
        background: #f2f2f2;
        /* Muda a cor de fundo ao passar o mouse */
    }
}




.categoryTitle-box {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
    color: #757575;
    font-weight: 600;
    font-size: 16px;
}

.inputFocus,
.inputFocusFirst,
.inputFocusAr {
    border: 2px solid #e2e8f0;
    font-size: 16px;
    height: 50px;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
    width: 96.8%;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 15px;
    transition: all 0.3s ease;
    background-color: #fff;
}



.inputFocus:focus,
.inputFocusFirst:focus,
.inputFocusAr:focus {
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
    outline: none;
}

.textAreaSpace {
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-right: 60px;
    font-size: 16px;
    border: 2px solid #e2e8f0;
    border-radius: 12px;
    background-color: #fff;
    resize: 'none';
    transition: all 0.3s ease;
    position: relative;
    /* Adicionado */
}

.textAreaSpace:hover {
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
}

.textAreaSpace:focus {
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
    outline: none;
}

.editButton {
    position: absolute;
    bottom: 0.75rem;
    right: 1.25rem;
    padding: 0.5rem;
    width: 36px;
    height: 36px;
    background-color: #8E59FF;
    color: white;
    border: none;
    border-radius: 50%;

    cursor: pointer;
    transition: all 0.3s ease;

}


.editButton:hover {
    background-color: #6d47cc;
    transform: scale(1.1);
}




.tabsListEnter {
    margin: 0;
    padding: 0 16px;
    display: flex;
    gap: 8px;
    border-bottom: none !important;
    margin-left: 16.5px;
    margin-right: 16.5px;

}


.tabsContainerEnter {
    max-width: 640px;
    margin: 0 auto;
    background: transparent;
    /* margin-top: 0px;
    margin-bottom: -15px; */
    /* Ajuste este valor conforme necessário */
}


.tabsContainerEnter .chakra-tabs__tab {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
    border: none !important;
    border-radius: 8px !important;
    color: #757575;
    background: #ffffff !important;
    transition: all 0.3s ease;
    margin-bottom: 0;
    box-shadow: none;
}

.tabsContainerEnter .chakra-tabs__tab:hover {
    background: #f8fafc !important;
}

.tabsContainerEnter .chakra-tabs__tab[aria-selected="true"] {
    background-color: #8E59FF !important;
    color: white !important;
    box-shadow: 0 2px 4px rgba(142, 89, 255, 0.2);
}

.tabsContainerEnter .chakra-tabs__tablist {
    border-bottom: none !important;
    margin-left: 0px;
}

.images-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1300px;
    background: white;
    border-radius: 16px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
}

.grid-content {
    display: grid;
    grid-template-columns: repeat(4, minmax(280px, 1fr));
    row-gap: 20px;
    column-gap: 20px;
    padding: 10px 20px 20px;
    height: calc(100vh - 220px);
    overflow-y: auto;
}

.post-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding: 8px;
    background: white;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.post-container:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.image-wrapper {
    position: relative;
    width: 280px;
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: 1;
    margin: 0 auto;
}

/* Estilos para os Radio Groups */
.publicContainer .chakra-radio-group {
    display: flex;
    gap: 16px !important;
    margin-bottom: 20px;
    margin-top: 8px;
}

.publicContainer .chakra-radio {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    border-radius: 16px;
    background-color: white;
    border: 1px solid #e2e8f0;
    transition: all 0.3s ease;
    height: 42px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.publicContainer .chakra-radio:hover {
    background-color: #f8fafc;
}

.publicContainer .chakra-radio[data-checked] {
    background-color: #8E59FF;
    border-color: #8E59FF;
    box-shadow: 0 1px 3px rgba(142, 89, 255, 0.1);
}

.publicContainer .chakra-radio span,
.publicContainer .chakra-radio__label {
    color: #64748b;
    font-size: 15px !important;
    font-weight: 500;
    transition: all 0.3s ease;
}

.publicContainer .chakra-radio[data-checked] span,
.publicContainer .chakra-radio[data-checked] .chakra-radio__label {
    color: white !important;
}

.publicContainer .chakra-radio__control {
    width: 20px !important;
    height: 20px !important;
    border: 2px solid #cbd5e1 !important;
    background-color: white !important;
    transition: all 0.3s ease !important;
    outline: none !important;
}

.publicContainer .chakra-radio[data-checked] .chakra-radio__control {
    background-color: white !important;
    border-color: white !important;
}

.publicContainer .chakra-radio[data-checked] .chakra-radio__control[data-checked]:before {
    background-color: #8E59FF !important;
    transform: scale(0.55) !important;
}

/* Estilos para o Checkbox no publicContainer */
.publicContainer .chakra-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
}

.publicContainer .chakra-checkbox__control {
    width: 20px !important;
    height: 20px !important;
    border: 2px solid #e2e8f0 !important;
    border-radius: 6px !important;
    background-color: white !important;
    transition: all 0.3s ease !important;
}

.publicContainer .chakra-checkbox[data-checked] .chakra-checkbox__control {
    background-color: #8E59FF !important;
    border-color: #8E59FF !important;
}

.publicContainer .chakra-checkbox__label {
    color: #64748b;
    font-size: 15px;
    font-weight: 500;
}

/* Estilos para o Select/Combo box no publicContainer */
.publicContainer select,
.publicContainer .chakra-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    padding: 10px 16px;
    font-size: 15px;
    color: #64748b;
    height: 42px;
    width: calc(100% - 127.5px);
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
}

.publicContainer select::-ms-expand {
    display: none;
}

.publicContainer select:hover,
.publicContainer .chakra-select:hover {
    border-color: #cbd5e1;
    background-color: #f8fafc;
}

.publicContainer select:focus,
.publicContainer .chakra-select:focus {
    border-color: #8E59FF;
    box-shadow: 0 0 0 1px #8E59FF;
    outline: none;
}

.dobra3-container {
    margin-top: 77.5px;
    background-color: #fff;
    padding: 20px;
    height: 520px;
    border-radius: 10px;
    width: 1250px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;
}

.dobra3-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    margin-top: -5px;
    padding: 10px;
}

.dobra3-selection-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 20px;
}

.dobra3-combo-box {
    width: 80%;
    margin-bottom: 20px;
    display: flex;
    gap: 40px;
    padding: 10px;
}

.dobra3-preview {
    display: flex;
    width: 800px;
    height: auto;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.dobra3-preview img {
    width: 300px;
    height: auto;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    object-fit: contain;
}

.tabSilverDesign {
    max-width: 640px;
    margin: 0 auto;
    background: transparent;
}

@media (max-width: 768px) {

    /* Ajustes para dispositivos móveis (portrait) */
    html,
    body,
    #root {
        height: 0;
    }

    .image-container {
        left: 55px;
    }

    .slide-image {
        width: 100px;
        /* ou qualquer outro valor que funcione para você */
        height: auto;
    }

    .swiper {
        height: 100dvh;
        width: 100%;
    }


    .swiper-slide {
        padding-top: 0px;
        padding-bottom: 0px;
        height: 100%;
    }

    .swiper-pagination-bullet {
        display: none;
        /* Isso vai esconder as bolinhas do Swiper */
    }

    .gridContainer {
        grid-template-columns: 1fr;
        /* Ajusta o grid para ter apenas 1 coluna, assim, cada box fica alinhado na vertical. */
        grid-template-rows: 1fr 1fr 1fr 1fr;
        /* Como temos 4 elementos em uma "página", precisamos de 4 linhas. */

    }

    .stkBegin.zero-margin {
        margin-top: 0;
        margin-bottom: 0px;
    }

    .stkBegin {
        padding-right: 10px;
    }

    .stkBegin.spaceTwo {
        padding-right: 0px;
    }

    .inputAreaEspecial {
        margin-left: 1px;
        border: 3px solid #e2e8f0;

    }

    .categoryTitle-t {
        font-size: 14px;
        width: 80%;
        text-align: center;
        padding-bottom: 10px;
        color: #757575;
        font-weight: bold;
    }

    .categoryTitle-box {
        font-size: 14px;
        color: #757576;
        font-weight: bold;
        margin-bottom: 0px;
    }

    .nicheButton {
        font-size: 0.8em;
        width: 250px;
        height: 50px;
        padding: 30px 30px;
    }

    .selectButton {
        width: 150px;
        height: 36px;
        font-size: 14px;
    }

    h2 {
        font-size: 0.8em;
        /* Reduzir um pouco o tamanho da fonte para otimizar o espaço */
        margin: 0;
        /* Remover margens padrão */
    }

    label {
        font-size: 0.8em;
        /* Tamanho menor para labels */
        display: block;
        margin-top: 10px;
        /* Espaçamento entre elementos */
        margin-bottom: 5px;
    }

    .slideContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    /* Ajustando o input */
    .inputFocus {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: calc(100% - 9px);
        padding: 8px;
        margin-left: 0px;
        margin-right: 0px;
        box-sizing: border-box;
        font-size: 16px;
    }

    .inputFocusFirst {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 10%;
        background-color: #fff;
        padding: 8px;
        margin-left: 0px;
        margin-bottom: 10px;
        box-sizing: border-box;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        border: 1px solid #e2e8f0;
        font-size: 16px;
    }

    .labelTopic.btn {
        margin-bottom: 10px;
    }

    .inputFocusAr {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: calc(100% - 9px);
        padding: 8px;
        margin-left: 0px;
        margin-right: 0px;
        box-sizing: border-box;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        border: 1px solid #dddddd;
        font-size: 16px;
        margin-bottom: 15px;
    }

    .textAreaSpace {
        width: 120%;
        height: 105%;
        appearance: none;
        font-size: 16px;
        border-color: transparent;
        border-width: 1;
        border-radius: 5px;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

    }

    .divTextArea {
        display: flex;
        width: 100%;
        height: 82%;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }


    .editButton {
        bottom: 05px;
        right: 30px;
    }

    .tabsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 85px;
        margin-left: 0px;
        margin-right: 0px;
        /* Ajuste este valor conforme necessário */
    }

    .tabsList {

        width: 330px;

    }

    .inputAreaTxt {
        border-width: 1;
        border-radius: 5px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        width: 330px;
        margin-left: 0px;
        height: 60px;
    }

    .divAreaImage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-top: 0px;
    }

    .imagesContainer {
        width: 330px;
        box-sizing: border-box;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .innerContainer4x5 {
        width: 100%;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    }

    .firstImage4x5 {
        margin-left: -75px;
        width: 280px;
        height: 350px;
    }

    .subsequentImage4x5 {
        width: 280px;
        height: 350px;
       
    }

    .imagesBank {
        height: 303px;
        margin: 50px auto 110px;
    }

    .imagesOwn {
        height: 351.5px;
        margin: 1.5px auto 110px;
        overflow-y: hidden;
    }

    .buttonGroupImg {
        width: 100%;
        display: grid;
        height: 60px;
        justify-content: center;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 10px;
        gap: 0px;

    }


    .buttonStepper {

        width: 330px;
        height: 35px;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 75px;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }




    .uploadContainer {
        flex-direction: column-reverse;
        /* Elementos um sobre o outro */
        display: flex;
        /* Empilha os elementos verticalmente */
        gap: 10px;
        /* Espaçamento entre os elementos */
        height: 100%;
    }

    .IAContainer {
        flex-direction: column-reverse;
        /* Elementos um sobre o outro */
        display: flex;
        /* Empilha os elementos verticalmente */
        gap: 10px;
        /* Espaçamento entre os elementos */
        height: 100%;
        width: 100%;
    }

    .imagePreviewArea {
        flex: 4;
        height: 0%;
        border-right: 0px solid #ccc;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0);

    }

    .imagePreview {
        max-width: 95%;
        max-height: 95%;
        margin-top: 0px;
    }


    .imagePreviewOwn {
        max-width: 85%;
        max-height: 85%;
        margin-top: 0px;
    }


    .fileInputContainer {
        display: flex;
        flex: 1;
        height: 10%;
        width: 100%;
        margin-top: 10px;
        align-items: center;
        justify-content: center;
        /* Ocupa toda a largura disponível */
    }


    .fileInput {
        width: 100%;

    }

    .btnNew {
        margin-top: 13px;
        width: 330px;
        align-items: center;
        margin-right: 0px;
        display: flex;
        justify-content: flex-end;
        gap: 0px;
    }

    .customFileButton {

        height: 35px;

        /* Adicione mais estilos conforme necessário */
    }

    .radio-group-footer {
        position: absolute;
        margin-left: -140px;
        margin-top: 73%;
        margin-bottom: 0px;
        width: 190px;
        z-index: 10;
        /* Outros estilos que desejar para alinhar o RadioGroup corretamente */
    }

    .labelTopic {
        margin-top: 0px;
        width: 100%;
    }

    .labelTopic.topic {
        width: 100%;
        margin-bottom: 10px;
    }

    .labelTopicFirst {
        margin-top: 12.5px;
        width: 100%;
    }

    .labelTopicFirst.btn {
        margin-top: 15px;
        margin-bottom: 12.5px;
    }

    .radioGroup {
        margin-right: 20px;
        /* Reduzir a margem entre as opções */
        display: flex;
        align-items: center;
        flex-direction: row
    }

    .top-btn-wrapper {
        position: absolute;
        top: 65px;
        /* margem do rodapé */
        left: 50%;
        transform: translateX(-50%);
    }


    button.googleButton {

        padding: 12px;
        margin-top: 20px;
        /* Espaçamento entre botão e input */
    }

    .googleButton {
        min-width: 120px;
        height: 32px;
        font-size: 12px;
    }


    button.googleButtonTxt {

        padding: 12px;
        margin-top: 20px;
        /* Espaçamento entre botão e input */
    }

    .googleButtonTxt {
        min-width: 98px;
        height: 32px;
        font-size: 12px;
    }

    .lastButton {
        min-width: 95px;
        height: 32px;
        font-size: 12px;
    }

    button.lastButton {

        padding: 12px;
        margin-top: 10px;
        /* Espaçamento entre botão e input */
    }

    .buttonFinal {
        width: 120%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 25px;
        gap: 10px;
    }

    .blink {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        /* Seu SwiperSlide pode ter um tamanho específico, se sim, defina aqui */
        width: 70%;
        height: 100%;
    }

    .swiper-button-prev {
        top: auto;
        bottom: 5px;
        left: 20%;
        transform: scale(1.5);
    }

    .swiper-button-next {
        top: auto;
        bottom: 5px;
        right: 20%;
        transform: scale(1.5);
    }

    .footer-btn-wrapper {
        bottom: 7px;
    }

    .userInfoDesktop {
        display: none;
    }

    .userInfoMobile {
        display: block;
        margin-left: 10px;
    }

    .textHeadline {
        text-align: center;
    }

    .buttonGroup {
        width: 120%;
        justify-content: center;
        margin-bottom: 30px;
        gap: 15px;
        /* Centraliza os botões */
    }

    .buttonGroupSave {

        width: 120%;
        justify-content: right;
        margin: 0px;
        margin-top: -10px;
        margin-bottom: 30px;
        /* Centraliza os botões */
    }

    .carouselBoxStories {

        width: 420px;
        height: 298px;
        padding-left: 0;
        padding-right: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .carouselBox h2 {
        display: none;
    }

    .carouselContent {
        flex-direction: column;
        align-items: center;
        width: 350px;
        margin-bottom: 0;
        padding-bottom: 0;
        margin-top: -20px;
    }



    .containerExter {
        display: 'flex';
        flex-direction: 'column';
        align-items: 'center';
        justify-content: 'center';
        height: auto;
    }

    .previewImage img {
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;

    }

    .selectionArea {
        width: 100%;
    }

    .containerComboBox {

        width: 90%;
        margin: 0;
        gap: 25px;

    }

    .stk {

        padding-bottom: 5px;
    }

    .slcSlidesLay {
        border: 1px solid #dddddd;
        border-radius: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }

    .btnSelect button {
        margin: 0;
    }


    .container {
        margin-bottom: 0px;
    }

    .divImagesAjs {
        margin-top: 20px;
        margin-bottom: 0px;
    }



    .divImagesAjs.selected {
        /* Move o elemento 3px para cima */
        border: 3px solid #8E59FF;
        box-shadow: 0 0 10px #8E59FF;
    }

    .thumbnailImage {
        width: 200px;
        /* Ajuste conforme necessário para o tamanho desejado no mobile */
        height: 200px;
        /* Ajuste conforme necessário */
    }

    .myCustomTitle {
        font-size: 18px;
    }

    .my-swal {
        font-size: 12px;
        width: 85%;

    }

    .my-swal-alert {
        font-size: 14px;
        width: 85%;
    }


    .my-swal-special {
        width: 85%;
    }

    .my-swal-alert .swal2-checkbox input {
        position: absolute;
        background-color: #ffffff;

    }

    .pexelsImg {
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        padding: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .star-rating label {
        margin-top: 0px;
        font-size: 40px;
        padding: 0;
        margin-right: 8px;
        /* Espaçamento entre as estrelas */
        cursor: pointer;
        color: #e0e0e0;
        /* Cor das estrelas apagadas */
        transition: color 0.2s;
        display: inline-block;
    }

    .star-rating {
        margin-top: 15px;
        font-size: 0;
        direction: rtl;
    }

    .my-textarea {
        margin-top: 10px;
    }

    .swal-input {

        width: 95%;
        height: 50px;
        border: 1px solid #767676;

    }

    .menu-icon {
        font-size: 35px;
    }

    .image-container_01 {
        position: absolute;
        left: 10px;
        padding: 0;
        background-color: transparent;
    }

    .headline-grid-container {

        height: 90%;
        padding-top: 0px;
        padding-bottom: 20px;
        width: 310px;
        grid-template-columns: 1fr;
        grid-gap: 15px;
        /* Espaço menor entre os itens do grid */
        /*padding: 10px; /* Padding menor */
        margin-top: -10px;
    }


    .headline-button {

        margin-top: 10px;
        padding: 20px;
        width: 100%;
        height: 200px;
        /* Largura completa para se ajustar ao contêiner */
        /* Altura automática */
        font-size: 14px;
        /* Fonte menor */

    }


    .headline-button.second-button {
        height: 250px;
        /* Altura para o segundo botão no mobile */
    }


    .outcome-grid-container {

        height: 80.5%;
        padding-top: 0px;
        width: 310px;
        grid-template-columns: 1fr;
        grid-gap: 15px;
        /* Espaço menor entre os itens do grid */
        /*padding: 10px; /* Padding menor */
        margin-top: -10px;
    }

    .outcome-button {

        margin-top: 10px;
        padding: 20px;
        width: 100%;
        height: 200px;
        /* Largura completa para se ajustar ao contêiner */
        /* Altura automática */
        font-size: 14px;
        /* Fonte menor */

    }

    .outcome-button.second-button {
        height: 250px;
        /* Altura para o segundo botão no mobile */
    }


    .categoryTitleTema {
        margin-top: 50px;
        width: 100%;
        padding-left: 0px;
        color: #757575;
        font-weight: bold;
        font-size: 16px;

    }

    .inputFocusTxt {
        border-color: transparent;
        font-size: 16px;
        height: 32px;
        border-width: 1;
        border-radius: 5px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        width: 170px;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 20px;
        margin-bottom: 0px;
        padding: 10px;
    }

    .firstImage {
        
        margin-left: -205px;
        width: 295px;
        height: 295px;
    }

    .subsequentImage {
        margin-top: 0px;
        margin-left: -130px;
        width: 295px;
        height: 295px;
        object-fit: cover;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        flex-shrink: 0;

        /* Impede que as imagens encolham */
    }

    .floatingButtonsContainer {
        left: 290px;
        position: sticky;
        margin-top: -200px;
    }

    .outerContainer {
        align-items: center;
        justify-items: center;
        min-width: 355px;
        /* Largura mínima */
        width: fit-content;
        /* Permite crescer se necessário */
        height: 84.5%;
        margin-top: 25px;
    }


    .innerContainer {

        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        gap: 150px;
        padding: 0px;

    }

    .buttonFinalStories {
       margin-top: 10px;
        margin-bottom: 70px;
        
    }


    .captionContainer {
        margin-left: 10px;
        width: 480px;
        height: 100px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .floatingButtonsContainerStories {
        top: 120px;
        /* Ajuste conforme necessário */
        right: 40px;
    }



    .storyImage.firstImage {

        width: 213.82px;

    }

    .storyImage.subsequentImage {
        width: 213.82px;
    }

    .btnSelect {
        margin-top: -15px;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        margin-right: 0px;
        max-width: 340px;
        gap: 60px;
    }

    .btnSelectStories {

        margin-top: -25px;
        margin-right: 25px;
    }

    .radioLay {
        margin-top: 5px;
        margin-left: 15px;
        margin-right: 15px;
        flex: 1;
        display: flex;
        justify-content: baseline;
        align-items: center;
        min-width: 0;
        max-width: 100%;
    }

    .btnPromo {
        margin-bottom: -80px;
        margin-top: 27px;
    }

    .btnPromo2 {
        margin-bottom: -5px;
        margin-top: -25px;
    }

    .tabsContainer .chakra-tabs__tab:hover {
        /* box-shadow: none !important;*/
        background: #f8fafc;
    }

    .tabsContainer .chakra-tabs__tab[aria-selected="true"] {
        background-color: #8E59FF;
        color: white;
        box-shadow: 0 2px 4px rgba(142, 89, 255, 0.2);
    }

    .firstEnter {
        margin-top: 25px;
        width: 125%;
    }

    .publicContainer {
        width: 350px;
        padding: 0px;
        border-radius: 10px;
        margin-right: 0px;
        margin-left: 0px;
        padding-bottom: 10px;
        padding-top: 5px;
    }

    .tabsContainerEnter {
        margin-top: 0px;
        width: 380px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        /* padding: 0 10px; */
    }

    .checkBoxEnter {
        margin-top: 0px;
        padding-right: 10px;
    }

    .dividerFirstStry {
        margin-top: 0px;
        margin-bottom: 5px;
    }

    .dividerFirst {
        margin-bottom: 5px;
    }

    .tabsContainerEnter .chakra-tabs__tab[aria-selected="true"] {
        background-color: #8E59FF !important;
        color: white !important;
        /* Ajuste para sua cor desejada */
        border-bottom: 0px !important;
        /* Ajuste para sua cor desejada */
    }

    .slidesContainer {
        width: 120%;
    }

    .textAreaSpaceDin {
        padding: 10px 10px 10px 10px;
        /* Espaçamento interno para a área de texto */
    }

    .lblEditText {
        margin-left: 40;
    }

    .buttonStyle {
        width: 10%;
    }

    .previewImageStories {
        display: flex;
        width: 68%;
        height: auto;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;

    }

    .previewImageStories img {
        width: 150px;
        height: auto;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    }

    .innerContainerStories {
        margin-top: 70px;
        width: 175%;
        height: 75%;
        overflow-x: auto;
    }

    /* Título da headline */
    .headlines-section__title {
        font-size: 1rem;
    }

    /* Texto da headline */
    .headlines-section__text {
        font-size: 0.9rem;
    }

    .catDesing {
        /* margin-top: -215px;
        margin-bottom: 0px;
       font-size: 14px;  */
        display: none;
    }

    .templateImage {

        width: 120%;
        height: 120%;
        object-fit: contain;
        /* Ajusta a imagem para não cortar excessivamente */
    }

    .previewImage {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;

    }


    .templateGrid {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 250px;
        width: 220px;
        height: 265px;
        position: fixed;
        /* Aumentado para dar mais espaço vertical */
    }

    .carouselBox {
        margin-top: -230px;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        box-sizing: border-box;

    }

    .nonStoriesII {

        height: 280px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .previewContainer {
        margin-top: 250px;
        margin-bottom: 20px;
        height: 70px;
        width: 95%;
        border-radius: 0px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }


    .previewContent {
        height: 50px;
        padding: 20px;
    }

    .previewContent h4 {
        font-size: 18px;
        margin-top: -20px;
        font-weight: bold;
        margin-bottom: 0px;
        transition: color 0.3s ease;
    }

    .previewContent p {
        font-size: 14px;
        line-height: 1.5;
        transition: color 0.3s ease;
        margin-top: -10px;
    }

    .iconEye {
        margin-top: 0px;
        margin-left: 10px;
    }

    .colorGroup {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 110%;
        margin-left: -15.5px;
        height: 92px;
        margin-top: -10px;
        margin-bottom: 0px;
        padding: 0;
        overflow-y: auto;
    }


    .colorGroupStories {
        margin-top: 10px;
        width: 100%;

    }

    .tabSilverDesign {
        margin-top: -10px;
        width: 100%;
    }

    .style-customization-container {
        margin-top: -10px;
        margin-left: -6.5px;
        height: 92px;
        width: 104%;
        overflow-y: auto;
    }


    .header-with-checkbox {
        margin-top: -10px;
        margin-bottom: 10px;
    }


    .color-grid {
        margin-bottom: 10px;
    }

    .stk {
        margin-top: 10px;
        margin-bottom: 0px;
    }



    .templateBox {
        position: relative;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        width: 200px;
        height: 220px;
        /* Alterado para proporcionar mais altura vertical */
        background: #8E59FF;
        /* Altura mínima garantida */
    }

    .templateContainer {
        position: relative;
        width: 100%;
        height: calc(100% - 30px);
        /* Aumentado o espaço reservado para o título */
    }

    .templateName {
        height: 40px;
    }

    .titleWithIcon {
        display: flex;
        align-items: center;
        margin-top: -20px;
        margin-bottom: 0px;
    }



    .inputFocus,
    .inputFocusFirst,
    .inputFocusAr,
    .textAreaSpace {
        width: calc(100% - 20px);
        margin-left: 0;
        margin-right: 0;
    }

    .categoryTitle-box {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .images-grid {
        width: 100%;
        margin: 0;
        border-radius: 0;
    }

    .grid-content {
        grid-template-columns: 1fr;
        padding: 16px;
        row-gap: 16px;
        height: calc(100vh - 180px);
    }

    .post-container {
        padding: 4px;
        width: 100%;
    }

    .image-wrapper {
        width: 100%;
        max-width: 242px;
    }

    .publicContainer .chakra-radio-group {
        flex-direction: column;
        gap: 12px !important;
    }

    .publicContainer .chakra-radio {
        padding: 10px 16px;
        height: 44px;
    }

    .publicContainer .chakra-radio span,
    .publicContainer .chakra-radio__label {
        font-size: 14px !important;
    }

    .publicContainer select,
    .publicContainer .chakra-select {
        font-size: 14px;
        padding: 10px 14px;
        height: 44px;
    }

    .dobra3-container {
        width: 420px;
        height: 298px;
        padding-left: 0;
        padding-right: 0;
        border-radius: 8px;
        margin-top: 40px;
        overflow-y: auto;
    }

    .dobra3-content {
        flex-direction: column;
        align-items: center;
        width: 320px;
        margin-bottom: 0;
        padding-bottom: 0;
        padding: 10px;
    }

    .dobra3-selection-area {
        width: 100%;
        padding: 10px;
    }

    .dobra3-combo-box {
        width: 90%;
        margin: 0;
        gap: 25px;
        padding: 5px;
    }

    .dobra3-preview {
        padding: 10px;
    }

    .dobra3-preview img {
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        max-width: 300px;
    }

    .tabSilverDesign .chakra-tabs__tab {
        font-size: 13px;
        padding: 6px 12px;
    }



    .slcSlides {
        font-size: 13px;
        height: 32px;
        line-height: 32px;
        padding: 0 28px 0 10px;
        background-size: 14px;
    }





    .textContainer {
        width: 95%;
        padding: 20px 15px;
    }

    .textHeadline {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .headlines-grid-container {
        height: 77.5%;
        padding: 20px;
        width: 310px;
        grid-template-columns: 1fr;
        grid-gap: 15px;
        margin-bottom: 0;
        margin-top: 20px;
    }

    .headlines-button {
        margin-top: 10px;
        padding: 20px;
        width: 100%;
        height: 200px;
        font-size: 14px;
    }
}