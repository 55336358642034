.pci-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #f8f9fa;
}

/* Ajuste para remover as faixas brancas */
.pci-swiper .swiper {
  width: 100%;
  height: 100vh;
  background-color: #f8f9fa;
}

.pci-swiper .swiper-slide {
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}

/* Ajuste específico para o segundo slide */
.pci-swiper .swiper-slide:nth-child(2) {
  padding-top: 100px;
  /* Mantendo consistência com o primeiro slide */
}

.pci-content {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 40px;
  /* Aumentado de 30px para 40px para dar mais espaço entre os elementos */
  margin: auto;
  flex: 1;
  margin-top: 35px;
}

.pci-progress {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* Aumentado de 20px para 30px */
  margin-bottom: 20px;
  /* Adicionado margem inferior */
}

.progress-steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}

.progress-step {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9ecef;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #757575;
}

.progress-step.active {
  background-color: #8E59FF;
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(142, 89, 255, 0.2);
  color: white;
}

.progress-step.completed {
  background-color: #a881ff;
  color: white;
}

.progress-step svg {
  width: 20px;
  height: 20px;
}

.pci-card {
  width: 100%;
  background: #f8f9fa;
  border-radius: 16px;
  padding: 50px;
  /* Aumentado de 30px para 40px */
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 25px;
  /* Aumentado de 20px para 25px */
  animation: slideIn 0.3s ease;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.pci-card-icon {
  font-size: 32px;
  text-align: center;
  color: #8E59FF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pci-card-icon svg {
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
}

.pci-card-title {
  font-size: 24px;
  color: #2d3748;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.pci-card-input {
  width: 100%;
  padding: 18px;
  /* Aumentado de 16px para 18px */
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  font-size: 16px;
  transition: all 0.3s ease;
  background-color: #fff;
}

.pci-card-input:focus {
  border-color: #8E59FF;
  box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
  outline: none;
}

.pci-card-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.nav-button {
  width: 48px;
  /* Restaurado para o tamanho original */
  height: 48px;
  border-radius: 50%;
  border: none;
  background-color: #8E59FF;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(142, 89, 255, 0.2);
}

.nav-button:hover {
  background-color: #7040e0;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(142, 89, 255, 0.3);
}

.nav-button.disabled {
  background-color: #e2e8f0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.nav-button svg {
  font-size: 24px;
  /* Aumentado o tamanho do ícone */
}

/* Ajuste do botão de enviar */


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  z-index: 1000;
}

.publicContainer_pci {
  background-color: #fff;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  width: 640px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.inputFocus_pci {
  border-color: transparent;
  font-size: 16px;
  height: 40px;
  border-width: 1;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  width: 96.8%;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #dddddd;
}

.inputFocus_pci_02 {
  border-color: transparent;
  font-size: 16px;
  height: 40px;
  border-width: 1;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  width: 107.5%;
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #dddddd;
}

.labelTopic {
  margin-top: 0px;
}

.image-container_pci {
  position: absolute;
  top: 0px;
  left: 20px;
  padding: 0;
  background-color: transparent;
}

.image-container_pci_02 {
  position: absolute;
  top: 0px;
  left: 63px;
  padding: 0;
  background-color: transparent;
}

.divAjust {
  display: flex;
  flex-direction: row;
  gap: 30px
}

.divAjust_2 {
  display: flex;
  flex-direction: row;
  gap: 30px
}

.divTextAreaPCI {
  display: flex;
  width: 100%;
  height: calc(100vh - 180px);
  /* Ajustado para considerar o novo padding-top */
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin-top: 0;
  /* Removido margin-top pois já temos o padding-top no slide */
}

.buttonGroupPCI {
  width: 85%;
  max-width: 1200px;
  display: flex;
  justify-content: flex-end;
  margin: 20px auto;
  padding-right: 20px;
}

.googleButtonNew {
  padding: 8px 20px;
  border-radius: 80px;
  /* Mantendo consistência */
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #8E59FF;
  color: white;
  height: 48px;
  box-shadow: 0 2px 4px rgba(142, 89, 255, 0.2);
}

.googleButtonNew:hover {
  background-color: #7040e0;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(142, 89, 255, 0.3);
}

.buttonIcon {
  font-size: 18px;
}

.buttonContent {
  white-space: nowrap;
  font-size: 14px;
}

.custom-markdown li {
  line-height: 1.2;
  /* Ajusta a altura da linha para parágrafos e listas */
  margin-bottom: 5px;
  /* Ajusta o espaço inferior */
}

.custom-markdown p {
  line-height: 1.2;
  /* Ajusta a altura da linha para parágrafos e listas */
  margin-bottom: 5px;
  /* Ajusta o espaço inferior */
}

.custom-markdown ol {
  line-height: 1.2;
  padding-left: 20px;
  /* Recuo para listas */
}

.custom-markdown ul {
  line-height: 0.5;
  padding-left: 20px;
  /* Recuo para listas */
}

.custom-markdown h1,
.custom-markdown h2,
.custom-markdown h3,
.custom-markdown h4 {
  margin-top: 10px;
  /* Espaço superior dos títulos */
  margin-bottom: 10px;
  /* Espaço inferior dos títulos */
  line-height: 1.2;
  /* Altura da linha para títulos */
}

.markdownSpace {
  padding: 50px;
  /* Igualando ao padding do pci-card */
  background: #fff;
  border-radius: 16px;
  /*box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);*/
  border: 2px solid #e2e8f0;
  flex: 1;
  overflow-y: auto;
  white-space: pre-wrap;
  width: 85%;
  max-width: 1200px;
  margin: 0 auto;
}

.categoryTitle-boxpci {
  width: 100%;
  text-align: left;
  margin-bottom: 25px;
  color: #757575;
  font-weight: bold;
  font-size: 24px;
}

.divbtnPCI {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-bottom: 10px;
}

input::placeholder {
  color: #999;
  /* Cor do placeholder */
  font-style: italic;
  /* Estilo da fonte */
  opacity: 0.6;
  /* Certifique-se de que o placeholder é sempre visível */
}

@media (max-width: 1280px) {
  .inputFocus_pci_02 {
    width: 96.5%;
  }
}

@media (max-width: 768px) {

  .pci-swiper .swiper-slide,
  .pci-swiper .swiper-slide:nth-child(2) {
    padding-top: 80px;
  }

  .pci-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 380px;
    min-height: 100vh;
  }

  .pci-content {
    padding: 16px;
    gap: 30px;
    width: 100%;
    max-width: 100%;
  }

  .pci-progress {
    margin-top: 50px;
    gap: 20px;
    margin-bottom: 10px;
  }

  .pci-card {
    padding: 25px;
    /* Menor padding no mobile */
  }

  .progress-step {
    width: 32px;
    height: 32px;
    font-size: 16px;
  }

  .pci-card-title {
    font-size: 20px;
  }

  .nav-button {
    width: 40px;
    /* Tamanho reduzido mas ainda proporcional */
    height: 40px;
  }

  .nav-button svg {
    font-size: 20px;
  }

  .submit-button,
  .googleButtonNew {
    padding: 6px 16px;
    font-size: 13px;
    height: 40px;
    /* Altura reduzida mas mantendo proporção */
  }

  .buttonIcon {
    font-size: 16px;
  }

  .publicContainer_pci {
    width: 120%;
  }

  .divAjust {
    margin-top: 14px;
    flex-direction: column;
    gap: 9px
  }

  .divAjust_2 {
    margin-top: 10px;
    flex-direction: column;
    gap: 9px
  }

  .inputFocus_pci_02 {
    width: 97%;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    border: 1px solid #dddddd;
  }

  .inputFocus_pci {
    width: 97%;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    border: 1px solid #dddddd;
  }

  .divTitle {
    margin-top: 0px;
  }

  .categoryTitle-boxpci {
    width: 100%;
    text-align: left;
    margin-bottom: 15px;
    color: #757575;
    font-weight: bold;
    font-size: 14px;
    margin-top: -15px;
  }

  .divbtnPCI {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 30px;
  }

  .divTextAreaPCI {
    margin-top: -65px;
    height: 530px;
    width: 410px;
    padding: 0 10px;
  }

  .markdownSpace {
    width: 90%;
    padding: 30px;
  }

  .buttonGroupPCI {
    width: 90%;
   margin-bottom: 15px;
   margin-top: 22.5px;
   margin-right: 0px;
  }


  .my-swal-alert .swal2-confirm,
  .my-swal-alert .swal2-cancel {
    padding: 6px 20px !important;
    height: 40px !important;
    font-size: 13px !important;
  }

}

/* Estilos customizados para os botões do SweetAlert2 */
.my-swal-alert .swal2-confirm {
  border-radius: 80px !important;
  padding: 8px 24px !important;
  height: 48px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: #8E59FF !important;
  box-shadow: 0 2px 4px rgba(142, 89, 255, 0.2) !important;
  transition: all 0.3s ease !important;
}

.my-swal-alert .swal2-confirm:hover {
  background-color: #7040e0 !important;
  transform: translateY(-2px) !important;
  box-shadow: 0 4px 8px rgba(142, 89, 255, 0.3) !important;
}

.my-swal-alert .swal2-cancel {
  border-radius: 80px !important;
  padding: 8px 24px !important;
  height: 48px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: #e2e8f0 !important;
  color: #4a5568 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
}

.my-swal-alert .swal2-cancel:hover {
  background-color: #cbd5e0 !important;
  transform: translateY(-2px) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15) !important;
}

/* Ajuste do container do popup */
.my-swal-alert {
  padding: 2em !important;
}

.my-swal-alert .swal2-actions {
  gap: 12px !important;
}

/* Ajuste para mobile */