.reset-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
}

.reset-password form {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa;
}

.reset-password label {
    font-size: 14px;
    color: #333333;
    margin-bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.reset-password label span {
    margin-bottom: 0px;
}


.reset-password input[type="password"]:focus {
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
    outline: none;
    /* box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1); */

}


.reset-password input[type="password"]:hover {
    border-color: #8E59FF;
    box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1);
    outline: none;
    /* box-shadow: 0 0 0 3px rgba(142, 89, 255, 0.1); */

}

.reset-password input[type="password"] {
    padding: 10px;
    border: 2px solid #e2e8f0;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 14px;
    width: calc(100% - 20px);
    /* Reduce a largura em 20px para considerar o padding */

}

.reset-password .submit-button {
    cursor: pointer;
    background-color: #8E59FF;
    color: #ffffff;
    border: none;
    border-radius: 80px;
    padding: 10px;
    font-size: 14px;
    transition: background-color 0.3s ease;
    width: 100%;
    /* Reduce a largura em 20px para considerar o padding */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.reset-password .submit-button:hover {
    filter: brightness(95%);
}

.reset-password .submit-button:active {
    filter: brightness(75%);
}


.logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.logo-image {
    width: 300px;
    height: auto;
    /* Mantém a proporção da imagem ao redimensionar */

}


@media (max-width: 768px) {

    .logo-image {
        width: 250px;
        height: auto;
        /* Mantém a proporção da imagem ao redimensionar */

    }

    .reset-password form {
        width: 75%;
        padding: 15px;
    }

    .reset-password input[type="password"] {
        font-size: 16px;
    }

    .reset-password {
        height: 85vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .myCustomTitle {
        font-size: 18px;
    }

    .my-swal-alert {
        font-size: 14px;
        width: 85%;
    }

}